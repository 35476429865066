import clsx from 'clsx';
import { useContext, useState } from 'react';
import { ShowContext } from '../../common';
import AutoHeight from '../AutoHeight';
import RelateItem from './RelateItem';

import style from './ShowRelated.module.scss';
import SpriteIcon from '../SpriteIcon';

function ShowRelated({}) {
  const show = useContext(ShowContext);

  const [open, setOpen] = useState(!false);

  return (
    <div className={clsx(style.ctn)}>
      <button
        type="button"
        className={clsx(style.head)}
        onClick={() => setOpen(!open)}
        open={open}
      >
        <SpriteIcon name="arrowtorightshort" width="12" height="20" />
        <span>Связанное</span>
      </button>

      <AutoHeight open={open}>
        <div className={clsx(style.relatedContent)}>
          {show.related.map((props) => {
            return (
              <ShowContext.Provider key={props.id} value={props}>
                <RelateItem className={clsx(style.relate)} />
              </ShowContext.Provider>
            );
          })}
        </div>
      </AutoHeight>
    </div>
  );
}

export default ShowRelated;
