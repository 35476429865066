import clsx from 'clsx';
import svgSprite from '../assets/sprites/svg-sprite.svg';

function SpriteIcon({ name, width, height, className }) {
  return (
    <svg
      className={clsx(className)}
      width={width ?? height}
      height={height ?? width}
    >
      <use href={`${svgSprite}#${name}`} />
    </svg>
  );
}

export default SpriteIcon;
