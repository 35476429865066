import clsx from 'clsx';
import { get } from 'lodash';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import classes from './AutoHeight.module.scss';

function AutoHeight({
  children,
  useMaxHeight,
  transitionMs = 500,
  style = Object(),
  className,
  open,
  minMaxHeight = 0,
  onContentHeight = Function,
  ...props
}) {
  const minMaxHeightPx = minMaxHeight + 'px';

  const [height, setHeight] = useState(open ? 'auto' : minMaxHeightPx);

  const containerRef = useRef(null);

  const transition = `${
    useMaxHeight ? 'max-height' : 'height'
  } ${transitionMs}ms`;

  useEffect(() => {
    onContentHeight(get(containerRef.current, 'children.0.scrollHeight'));
  });

  useEffect(() => {
    const contentHeight = get(containerRef.current, 'children.0.scrollHeight');

    if (contentHeight === undefined) {
      return;
    }

    if (open) {
      setHeight(contentHeight + 'px');

      const timeout = setTimeout(() => {
        setHeight('auto');
      }, transitionMs);

      return () => {
        clearTimeout(timeout);
      };
    } else {
      if (height === minMaxHeightPx) return;
      setHeight(contentHeight + 'px');

      const timeout = setTimeout(() => {
        setHeight(minMaxHeightPx);
      }, 100);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [open]);

  return (
    <div
      ref={containerRef}
      {...props}
      className={clsx(className, classes.ctn)}
      style={{
        ...style,
        transition,
        [useMaxHeight ? 'maxHeight' : 'height']: height,
        overflow: height === 'auto' ? null : 'hidden',
      }}
    >
      {children}
    </div>
  );
}

export default AutoHeight;
