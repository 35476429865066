import clsx from 'clsx';
import { useRef, useState } from 'react';
import Comment from './Comment';

import style from './CommentRoot.module.scss';

function MoreBtn({ open, setOpen, length }) {
  return (
    <button
      onClick={() => setOpen(!open)}
      className={clsx(style.repliesShowAll)}
      type="button"
    >
      {!open ? (
        <span>показать все ответы ({length})</span>
      ) : (
        <span>скрыть ответы</span>
      )}
    </button>
  );
}

function CommentReplies({ replies }) {
  if (!replies) return <></>;

  const [open, setOpen] = useState(false);

  const limit = 2;

  const computedReplies = open ? replies : replies?.slice(0, limit);

  const commentElement = (replyProps) => {
    return (
      <Comment
        {...replyProps}
        key={replyProps.id}
        className={clsx(style.reply)}
      />
    );
  };
  return (
    <div className={clsx(style.replies)}>
      <div className={clsx(style.reply, style.repliesNoLast)}>
        {computedReplies?.slice(0, -1).map(commentElement)}
      </div>

      {computedReplies?.slice(-1).map(commentElement)}

      {replies.length > limit && (
        <MoreBtn open={open} setOpen={setOpen} length={replies.length} />
      )}
    </div>
  );
}

function CommentRoot({ className, replies, ...props }) {
  const ctnRef = useRef();

  return (
    <div ref={ctnRef} className={clsx(style.ctn, className)}>
      <Comment
        {...props}
        className={clsx(style.comment, {
          [style.commentCount]: replies?.length,
        })}
      />

      <CommentReplies replies={replies} />
    </div>
  );
}

export default CommentRoot;
