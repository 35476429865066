import style from './SearchBar.module.scss';
import SpriteIcon from '../SpriteIcon';
import SearchResult from './SearchResult';
import clsx from 'clsx';
import { getDemoShows } from '../../common';
import { useEffect, useRef, useState } from 'react';
import { offset, useFloating } from '@floating-ui/react-dom';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';

function SearchBar({ className }) {
  const [searchText, setSearchText] = useState('');

  const resultsShows = getDemoShows(searchText ? 4 : 0);

  const [open, setOpen] = useState(false);

  const inputRef = useRef(null);
  const parentRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const onClick = (event) => {
      if (
        parentRef.current.contains(event.target) ||
        menuRef.current?.contains(event.target)
      ) {
        inputRef.current.focus();
        return;
      }

      setOpen(false);
    };
    window.addEventListener('click', onClick);
    return () => window.removeEventListener('click', onClick);
  }, []);

  const { x, y, reference, floating, strategy } = useFloating({
    placement: 'bottom-end',
    strategy: 'fixed',
    middleware: [offset(12)],
  });

  const openComputed = !!resultsShows.length && open;

  return (
    <div className={clsx(className, style.container)}>
      <div
        ref={(node) => {
          parentRef.current = node;
          reference(node);
        }}
        className={clsx(style.SearchBar)}
      >
        <input
          ref={inputRef}
          value={searchText}
          onChange={(event) => {
            setSearchText(event.currentTarget.value);
          }}
          onFocus={() => setOpen(true)}
          type="text"
          className={clsx(style.input)}
        />
        <Link data-disabled={!searchText} to={`/search?s=` + searchText} className={clsx(style.button)}>
          <SpriteIcon width="24" name="lupa" />
        </Link>
      </div>
      {openComputed &&
        createPortal(
          <div
            className={clsx(style.results)}
            ref={(node) => {
              menuRef.current = node;
              floating(node);
            }}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <SearchResult shows={resultsShows} />
          </div>,
          document.querySelector('#root'),
        )}
    </div>
  );
}

export default SearchBar;
