import { every } from 'lodash';
import numeral from 'numeral';
import { createContext } from 'react';
import { EChipVariants } from './components/Chip';

export const anyValue = (obj) => !every(Object.values(obj), (value) => !value);

export const UserContext = createContext(null);
export const UserControlContext = createContext(null);

export const EUsersRoles = {
  VIEWER: 'VIEWER',
};
export const demoUser = {
  name: 'Jerwis',
  id: 23,
  role: EUsersRoles.VIEWER,
  imageSrc:
    'https://cdn.discordapp.com/attachments/782449164889489410/1029834975643697222/dfc5faf10d97e9c45fb29f8ecff17716_1.jpg',
};
export const getDemoUsers = (count) => {
  return new Array(count).fill(demoUser).map((user, index) => {
    return { ...user, id: index };
  });
};

export const demoGenre = {
  imageSrc:
    'https://cdn.discordapp.com/attachments/782449164889489410/987714286421962852/Rectangle_14.png',
  name: 'Сёнен',
  views: 25459,
  cardTextShadowColor: '#ff507a',
};

export const getDemoGenres = (count) => {
  const genres = [
    'Сёнен',
    'Исекай',
    'Гарем',
    'Футанари',
    'Сэйнен',
    'Романтика',
    'Школа',
    'Повседневность',
    'Драма',
    'Фэнтези',
  ];

  return new Array(count).fill(demoGenre).map((genre, index) => {
    return {
      ...genre,
      id: index,
      name:
        genres[index % genres.length] +
        (((index / genres.length) | 0) > 0 ? (index / genres.length) | 0 : ''),
    };
  });
};

export const demoShow = {
  id: 1,
  title: 'Эта фарфоровая кукла влюбилась',
  titleAlt: 'Sono Bisque Doll wa Koi wo Suru',
  genres: getDemoGenres(10),

  type: 'TV',
  season: 'Зима',
  year: '2022',
  status: 'Онгоинг',
  age: '12+',

  chips: [
    { text: 'TV', variant: EChipVariants.TYPE },
    { text: 'Зима', variant: EChipVariants.SEASON },
    { text: '2022', variant: EChipVariants.YEAR },
    { text: 'Онгоинг', variant: EChipVariants.STATUS },
  ],
  rating: {
    value: 4.8,
    count: 99999,
  },
  episodes: {
    released: 5,
    count: 12,
    durationMinutes: 24,
  },
  cast: {
    voice: getDemoUsers(7),
    sound: getDemoUsers(3),
  },
  description:
    'Застенчивый старшеклассник по имени Вакана с детства грезит о том, чтобы стать мастером изготовления традиционных японских кукол. И неспроста, ведь дедушка парня добрую половину жизни отдал этому ремеслу. К сожалению, как бы Вакана ни старался, а освоить главное — создание кукольных голов — ему никак не удаётся. Зато в пошиве миниатюрной одежды он, кажется, уже весьма преуспел... и не думал не гадал, что именно это поможет ему сблизиться с одноклассницей — красавицей Марин Китагавой. Всегда весёлая, общительная и такая недосягаемая, девушка, как оказалось, имеет не менее странное, чем у Ваканы, увлечение — эроге-игры и косплей персонажей из них. Едва узнав, что парень умеет обращаться со швейной машинкой, красотка тут же вгрызается в него мёртвой хваткой и, прилагая всё своё очарование, просит сшить для неё костюм. А вот в одних ли умениях дело или, быть может, тут замешано что-то ещё, будущему мастеру-кукольнику только предстоит узнать, попутно открыв для себя простую истину: вовсе не важно, чем ты увлечён, главное — что это тебе по душе!',

  studio: { text: 'CloverWorks', id: 77 },
  previewVideoSrc:
    'https://cdn.discordapp.com/attachments/782449164889489410/987421220939104356/ezgif-3-8b27268374.mp4',
  previewVideoImageSrc: 'https://cdn.discordapp.com/attachments/782449164889489410/1029832960674893864/785425_1_1.jpg',

  videoSrc:
    'https://cdn.discordapp.com/attachments/782449164889489410/987421220939104356/ezgif-3-8b27268374.mp4',
  videoImageSrc: 'https://cdn.discordapp.com/attachments/782449164889489410/1029832960674893864/785425_1_1.jpg',
  listItemImageSrc: 'https://cdn.discordapp.com/attachments/782449164889489410/1029832960674893864/785425_1_1.jpg',
  coverImageSrc:
    'https://cdn.discordapp.com/attachments/782449164889489410/1029830984302080080/unknown.png',
};

export const demoNews = {
  imageSrc:
    'https://cdn.discordapp.com/attachments/782449164889489410/987741395144028201/unknown.png',
  name: 'ВНИМАНИЕ! Лена (Orru) не хочет озвучивать 6-ой сезон ДжоДжо!',
  linkText: 'К посту ВК',
  linkHref: '/news/123',
  date: new Date().toISOString(),
};

export const ShowContext = createContext(null);

numeral.register('locale', 'ru', {
  delimiters: {
    thousands: '’',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'тыс',
    million: 'млн',
    billion: 'блн',
    trillion: 'трл',
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'ème';
  },
  currency: {
    symbol: '€',
  },
});

const demoComment = {
  id: 5,
  showId: 9,
  branchCommentId: 78,
  replyTo: null,
  author: demoUser,
  content:
    'Застенчивый старшеклассник по имени Вакана с детства грезит о том, чтобы стать мастером изготовления традиционных японских кукол. И неспроста, ведь дедушка парня добрую половину жизни отдал этому ремеслу. К сожалению, как бы Вакана ни старался, а освоить главное — создание кукольных голов — ему никак не удаётся. Зато в пошиве миниатюрной одежды он, кажется, уже весьма преуспел... и не думал не гадал, что именно это поможет ему сблизиться с одноклассницей — красавицей Марин Китагавой. Всегда весёлая, общительная и такая недосягаемая, девушка, как оказалось, имеет не менее странное, чем у Ваканы, увлечение — эроге-игры и косплей персонажей из них. Едва узнав, что парень умеет обращаться со швейной машинкой, красотка тут же вгрызается в него мёртвой хваткой и, прилагая всё своё очарование, просит сшить для неё костюм. А вот в одних ли умениях дело или, быть может, тут замешано что-то ещё, будущему мастеру-кукольнику только предстоит узнать, попутно открыв для себя простую истину: вовсе не важно, чем ты увлечён, главное — что это тебе по душе!',

  updatedAt: new Date(),
  createdAt: new Date(),

  rating: {
    positive: 56,
    negative: 2,
  },
};

export const demoNotifications = [
  {
    id: 0,
    tab: 'comment',
    type: 'comment-rating',
    comment: demoComment,
    viewed: false,
    createdAt: new Date(),
    name: '69 людей оценили твой комментарий:',
  },
  {
    id: 1,
    tab: 'comment',
    type: 'comment-reply',
    replyComment: demoComment, // author included
    originalComment: demoComment,
    viewed: true,
    createdAt: new Date(),
  },
  {
    id: 2,
    tab: 'show',
    type: 'show-update',
    name: 'Вышла новая серия: 6/12',
    show: demoShow,
    viewed: true,
    createdAt: new Date(),
  },
  {
    id: 3,
    tab: 'general',
    type: 'general',
    name: 'Важное оповещение!',
    description: 'Тебе было выдано предупреждение (1/3)',
    icon: 'info-fill',
    url: '/',
    viewed: false,
    createdAt: new Date(),
  },
  {
    id: 4,
    tab: 'general',
    type: 'achievement-received',
    name: 'Достижение получено',
    description: 'Orru SIMP',
    image:
      'https://cdn.discordapp.com/attachments/782449164889489410/1012776274504208515/EsYhteRXYAE4eop_1.png',
    achievementId: 2344,
    viewed: false,
    createdAt: new Date(),
  },
  {
    id: 5,
    tab: 'general',
    type: 'general',
    name: 'Важное оповещение!',
    description: 'Тебе было выдано предупреждение (1/3)',
    icon: 'chat-fill',
    url: '/',
    viewed: false,
    createdAt: new Date(),
  },
  {
    id: 6,
    tab: 'general',
    type: 'general',
    name: 'Важное оповещение!',
    icon: 'like-fill',
    url: '/',
    viewed: false,
    createdAt: new Date(),
  },
];

export const demoComments = [
  {
    ...demoComment,
    replies: [
      {
        id: 3,
        branchCommentId: 5,
        replyTo: 5,
        author: demoUser,
        content: 'Извини, но я не могу посягать на твоё законное место',

        updatedAt: new Date(),
        createdAt: new Date(),

        rating: {
          positive: 56,
          negative: 2,
        },
      },
      {
        id: 333,
        branchCommentId: 5,
        replyTo: 3,
        author: demoUser,
        content:
          'Застенчивый старшеклассник по имени Вакана с детства грезит о том, чтобы стать мастером изготовления традиционных японских кукол. И неспроста, ведь дедушка парня добрую половину жизни отдал этому ремеслу. К сожалению, как бы Вакана ни старался, а освоить главное — создание кукольных голов — ему никак не удаётся. Зато в пошиве миниатюрной одежды он, кажется, уже весьма преуспел... и не думал не гадал, что именно это поможет ему сблизиться с одноклассницей — красавицей Марин Китагавой. Всегда весёлая, общительная и такая недосягаемая, девушка, как оказалось, имеет не менее странное, чем у Ваканы, увлечение — эроге-игры и косплей персонажей из них. Едва узнав, что парень умеет обращаться со швейной машинкой, красотка тут же вгрызается в него мёртвой хваткой и, прилагая всё своё очарование, просит сшить для неё костюм. А вот в одних ли умениях дело или, быть может, тут замешано что-то ещё, будущему мастеру-кукольнику только предстоит узнать, попутно открыв для себя простую истину: вовсе не важно, чем ты увлечён, главное — что это тебе по душе!',

        updatedAt: new Date(),
        createdAt: new Date(),

        rating: {
          positive: 56,
          negative: 2,
        },
      },
      {
        id: 124743,
        branchCommentId: 5,
        replyTo: 5,
        author: demoUser,
        content: 'Извини, но я не могу посягать на твоё законное место',

        updatedAt: new Date(),
        createdAt: new Date(),

        rating: {
          positive: 56,
          negative: 2,
        },
      },
      {
        id: 1247243,
        branchCommentId: 5,
        replyTo: 5,
        author: demoUser,
        content: 'Извини, но я не могу посягать на твоё законное место',

        updatedAt: new Date(),
        createdAt: new Date(),

        rating: {
          positive: 56,
          negative: 2,
        },
      },
      {
        id: 1223447243,
        branchCommentId: 5,
        replyTo: 5,
        author: demoUser,
        content: 'Извини, но я не могу посягать на твоё законное место',

        updatedAt: new Date(),
        createdAt: new Date(),

        rating: {
          positive: 56,
          negative: 2,
        },
      },
      {
        id: 234,
        branchCommentId: 5,
        replyTo: 5,
        author: demoUser,
        content: 'Извини, но я не могу посягать на твоё законное место',

        updatedAt: new Date(),
        createdAt: new Date(),

        rating: {
          positive: 56,
          negative: 2,
        },
      },
      {
        id: 3434,
        branchCommentId: 5,
        replyTo: 5,
        author: demoUser,
        content: 'Извини, но я не могу посягать на твоё законное место',

        updatedAt: new Date(),
        createdAt: new Date(),

        rating: {
          positive: 56,
          negative: 2,
        },
      },
      {
        id: 33,
        branchCommentId: 5,
        replyTo: 5,
        author: demoUser,
        content: 'Извини, но я не могу посягать на твоё законное место',

        updatedAt: new Date(),
        createdAt: new Date(),

        rating: {
          positive: 56,
          negative: 2,
        },
      },
      {
        id: 2111,
        branchCommentId: 5,
        replyTo: 5,
        author: demoUser,
        content: 'Извини, но я не могу посягать на твоё законное место',

        updatedAt: new Date(),
        createdAt: new Date(),

        rating: {
          positive: 56,
          negative: 2,
        },
      },
    ],
  },
  {
    id: 111,
    branchCommentId: null,
    replyTo: null,
    author: demoUser,
    content: 'Извини, но я не могу посягать на твоё законное место',

    updatedAt: new Date(),
    createdAt: new Date(),

    rating: {
      positive: 56,
      negative: 2,
    },
  },
  {
    id: 133,
    branchCommentId: null,
    replyTo: null,
    author: demoUser,
    content: 'Извини, но я не могу посягать на твоё законное место',

    updatedAt: new Date(),
    createdAt: new Date(),

    rating: {
      positive: 0,
      negative: 2,
    },
  },
  {
    id: 1333,
    branchCommentId: null,
    replyTo: null,
    author: demoUser,
    content: 'Извини, но я не могу посягать на твоё законное место',

    updatedAt: new Date(),
    createdAt: new Date(),

    rating: {
      positive: 0,
      negative: 0,
    },
  },
];

export const getDemoShows = (count) => {
  return new Array(count).fill(demoShow).map((show, index) => {
    return { ...show, id: index };
  });
};

export const getDemoNews = (count) => {
  return new Array(count).fill(demoNews).map((news, index) => {
    return { ...news, id: index };
  });
};
demoShow.related = getDemoShows(5);
