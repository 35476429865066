import { useEffect, useMemo } from "react";

class Debounce {
  constructor() {
    this.callFunction = this._callFunction.bind(this);
    this.cancel = this._cancel.bind(this);
  }
  _callFunction(...props) {
    this.cancel();
    this.timeout = setTimeout(() => {
      this.timeout = null;
      this.func(...props);
    }, this.waitMs);
  }

  _cancel() {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
}

export const useDebouncedFunction = (func, waitMs) => {
  const debounce = useMemo(() => new Debounce(), []);
  debounce.func = func;
  debounce.waitMs = waitMs;
  useEffect(() => debounce.cancel);

  return debounce.callFunction;
};
