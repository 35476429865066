import clsx from 'clsx';
import { getDemoShows, ShowContext } from '../../common';

import style from './SearchResult.module.scss';
import ShowCardSearchResult from './ShowCardSearchResult';

function SearchResult({ shows, className }) {
  return (
    <div className={clsx(style.ctn, className)}>
      <div className={clsx(style.content)}>
        {shows.map((show) => {
          return (
            <ShowContext.Provider value={show} key={show.id}>
              <ShowCardSearchResult className={clsx(style.card)} />
            </ShowContext.Provider>
          );
        })}
      </div>
    </div>
  );
}

export default SearchResult;
