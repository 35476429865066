import { createContext, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import * as Color from 'color';

import style from './AddToList.module.scss';
import uniqueId from 'lodash/uniqueId';
import { find } from 'lodash';
import SpriteIcon from './SpriteIcon';

const ComponentContext = createContext([
  null,
  () => console.warn('not defined'),
  null,
]);

function LiComponent({ id, name, icon, color }) {
  const value = id ?? name;

  const [selectedValue, setValue, radioName] = useContext(ComponentContext);

  return (
    <label
      checked-dom={`${selectedValue === value}`}
      className={clsx(style.li)}
    >
      <input
        name={radioName}
        value={value}
        type="radio"
        checked={selectedValue === value}
        onChange={() => {
          setValue(value);
        }}
      />

      <SpriteIcon width="24" height="24" name={icon} />
      <span>{name}</span>
    </label>
  );
}

function AddToList({ className, onOpenClose = () => {}, mode }) {
  const [value, setValueState] = useState(null);
  const [open, setOpenState] = useState(false);

  const setOpen = (value) => {
    setOpenState(value);
    onOpenClose(value);
  };

  const setValue = (value) => {
    setValueState(value);
    setOpen(false);
  };

  const radioName = uniqueId('addtolist_');

  const values = [
    { id: 0, name: 'Смотрю', icon: 'eye', color: '#A8DAFF' },
    { id: 1, name: 'Запланировано', icon: 'clock', color: '#B9A8FF' },
    { id: 2, name: 'Просмотрено', icon: 'completed', color: '#C9FFA8' },
    { id: 3, name: 'Пересматриваю', icon: 'boroarrow', color: '#FFECA8' },
    { id: 4, name: 'Избранное', icon: 'favorite', color: '#FFA8D2' },
  ];

  const selected = find(values, { id: value });

  const borderColor = selected
    ? Color(selected.color).alpha(0.5).toString()
    : '';
  const fillColor = selected ? Color(selected.color).toString() : '';

  return (
    <div
      className={clsx(className, style.ctn, style[mode])}
      style={{ borderColor, '--fill': fillColor }}
    >
      <label className={style.addToListButton}>
        <input
          type="checkbox"
          checked={open}
          onChange={(event) => setOpen(event.currentTarget.checked)}
        />

        {selected ? (
          <div
            className={clsx(style.selected)}
            style={{ '--color': selected.color }}
          >
            <span>{selected.name}</span>
            <SpriteIcon width="24" height="24" name={selected.icon} />
          </div>
        ) : (
          <span>Добавить в список</span>
        )}

        <SpriteIcon width="12" height="29" name="arrowtoright" />
      </label>
      <div className={clsx(style.popup, { [style.popup_open]: open })}>
        <ComponentContext.Provider value={[value, setValue, radioName]}>
          <div className={style.list}>
            {values.map((props) => (
              <LiComponent {...props} key={props.id} />
            ))}

            <button
              className={style.clearbtn}
              type="button"
              onClick={() => {
                setValue(null);
              }}
            >
              <LiComponent name="Убрать из списка" icon="02" />
            </button>
          </div>
        </ComponentContext.Provider>
      </div>
    </div>
  );
}

export default AddToList;
