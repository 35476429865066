import clsx from 'clsx';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../common';
import Floating from '../Floating';
import SpriteIcon from '../SpriteIcon';
import UserAvatar from '../UserAvatar';

import style from './HeaderProfileMenu.module.scss';

function Menu({}) {
  const user = useContext(UserContext);

  const [theme, setTheme] = useState('dark'); // light

  const onChangeRadioTheme = (event) => {
    const value = event.currentTarget.value;

    setTheme(value);
  };

  return (
    <div className={clsx(style.menu)}>
      <Link
        to={'/users/' + user.id}
        className={clsx(style.top, style.linkHover)}
      >
        <UserAvatar className={clsx(style.avatar)} imageSrc={user?.imageSrc} />
        <div className={clsx(style.head)}>
          <div className={clsx(style.name)}>{user.name}</div>
        </div>
      </Link>

      <div className={clsx(style.bot)}>
        <div className={clsx(style.buttons)}>
          <Link to="/" className={clsx(style.button)}>
            <SpriteIcon
              className={clsx(style.icon)}
              name="favorites"
              width="36"
            />
            <span>Закладки</span>
          </Link>

          <Link to="/" className={clsx(style.button)}>
            <SpriteIcon
              className={clsx(style.icon)}
              name="settings"
              width="36"
            />
            <span>Настройки</span>
          </Link>
        </div>

        <label className={clsx(style.theme)}>
          <span>Тема сайта:</span>

          <input
            checked={theme === 'dark'}
            onChange={onChangeRadioTheme}
            value={theme === 'light' ? 'dark' : 'light'}
            name="theme"
            type="checkbox"
          />

          <div className={clsx(style.themeSwitcher)}>
            <div className={clsx(style.themeSwitcherBack)}></div>
            <div className={clsx(style.themeButton, style.themeButtonSun)}>
              <SpriteIcon className={clsx()} name="sun" width="32" />
              <SpriteIcon className={clsx()} name="sun" width="32" />
            </div>
            <div className={clsx(style.themeButton, style.themeButtonMoon)}>
              <SpriteIcon className={clsx()} name="moon" width="32" />
              <SpriteIcon className={clsx()} name="moon" width="32" />
            </div>
          </div>
        </label>

        <Link to="/sign/in" className={clsx(style.exit)}>
          <SpriteIcon className={clsx()} name="circle-out" width="36" />
          <span>Выйти</span>
        </Link>
      </div>
    </div>
  );
}

function HeaderProfileMenu({ className }) {
  const user = useContext(UserContext);

  if (user) {
    return (
      <Floating
        className={clsx(className, style.ctn)}
        menu={<Menu />}
        offsetPx={7}
        children={(open, setOpen) => (
          <>
            <input
              checked={open}
              onChange={(event) => {
                setOpen(event.currentTarget.checked);
              }}
              className={clsx(style.checkbox)}
              type="checkbox"
            />
            <UserAvatar imageSrc={user?.imageSrc} />
          </>
        )}
      />
    );
  }

  return (
    <Link to="/sign/in" className={clsx(className, style.ctn)}>
      <input className={clsx(style.checkbox)} type="checkbox" />

      <span className={clsx(style.guest)}>Войти</span>
    </Link>
  );
}

export default HeaderProfileMenu;
