import clsx from 'clsx';

import style from './SignIn.module.scss';

import SpriteIcon from '../SpriteIcon';
import { Link, Router, useNavigate, useRoutes } from 'react-router-dom';

import van from '../../assets/pictures/van.png';
import { useContext, useEffect, useRef, useState } from 'react';
import { anyValue, demoUser, UserControlContext } from '../../common';
import { DateField, PasswordField, TextField } from './InputField';
import { every, set } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

function FieldError({ errors, name }) {
  return (
    <div
      className={clsx(style.fieldError, { [style.errorShow]: errors[name] })}
    >
      {errors[name]}
    </div>
  );
}

function SignPasswordReset({}) {
  const [successEmail, setSuccessEmail] = useState('');
  const [timerValueSeconds, setTimerValueSeconds] = useState(0);
  const timerEndTime = useRef(moment());

  const [form, setForm] = useState({
    nameEmail: '',
  });

  const [errors, setErrors] = useState({
    nameEmail: '',
  });

  const setField = (event) => {
    const { name, value } = event.currentTarget;

    setForm({ ...form, [name]: value });

    delete errors[name];
    setErrors(errors);
  };

  const updateTimerValue = () => {
    const seconds = timerEndTime.current.diff(moment(), 'seconds');

    setTimerValueSeconds(Math.max(0, seconds));
  };

  useEffect(() => {
    const interval = setInterval(updateTimerValue, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const restartTimer = (minutes) => {
    timerEndTime.current = moment().add(minutes, 'minutes');
    updateTimerValue();
  };

  const signUp = () => {
    const newErrors = {};

    if (!form.nameEmail) {
      set(newErrors, 'nameEmail', 'Введите логин или email');
    }

    if (anyValue(newErrors)) {
      setErrors(newErrors);
      return;
    }

    if (form.nameEmail === '111') {
      setSuccessEmail('a***r@gmail.com');
      restartTimer(3);

      alert('пример станицы которая откроется из письма: ' + location.origin + '/sign/password-new-set');

      // send email
    } else {
      alert('Для теста попробуйте 111');
      restartTimer(0);
      setSuccessEmail('');
      setErrors({
        // ошибки можно возвращать в ответе с сервера чтобы не дублировать логиту здесь
        nameEmail: 'Мы искали, но не нашли тебя. Может перепутал что-то?',
      });
    }
  };

  const minutes = numeral((timerValueSeconds / 60) | 0).format('0');
  const seconds = numeral(timerValueSeconds % 60).format('00');
  const timerString = `${minutes}:${seconds}`;

  return (
    <div className={clsx(style.ctn)}>
      <Link to="/sign/in" className={clsx(style.topNavBtn)}>
        <SpriteIcon name="arrow-left-thicc" width="24" />
        <span>К авторизации</span>
      </Link>

      <div className={clsx(style.cringe, style.titleSignUp)}>
        Укажи почту или логин, забывашка)
      </div>

      <div className={clsx(style.message, style.messageTop)}>
        {errors.nameEmail && (
          <span className={clsx(style.messageRed)}>{errors.nameEmail}</span>
        )}
        {successEmail && (
          <>
            <span className={clsx(style.messageYellow)}>
              Ссылка отправлена на почту
            </span>
            <span
              className={clsx(style.messageWhite)}
            >{` ${successEmail}`}</span>
          </>
        )}
      </div>

      <TextField
        disabled={!!timerValueSeconds}
        className={clsx(style.passwordResetInputRowNameEmail)}
        icon="f-login-mail"
        placeholder="Email или логин"
        name="nameEmail"
        value={form.nameEmail}
        onChange={setField}
        error={errors['nameEmail']}
      />

      <div className={clsx(style.message, style.messageTimer)}>
        {!!timerValueSeconds && (
          <>
            <span>Повторно отправить можно будет через:</span>
            <span
              className={clsx(style.messageLightGray)}
            >{` ${timerString}`}</span>
          </>
        )}
      </div>

      <div className={clsx(style.captha, style.capthaPasswordReset)}>
        тут будет капча
      </div>

      <button
        disabled={anyValue(errors) || timerValueSeconds}
        type="button"
        className={clsx(style.button, style.buttonSendPasswordReset)}
        onClick={signUp}
      >
        Отправить
      </button>

      <Link to="/" className={clsx(style.doc)}>
        Политика конфиденциальности
      </Link>
    </div>
  );
}

export default SignPasswordReset;
