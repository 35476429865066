import clsx from 'clsx';

import style from './SignIn.module.scss';

import SpriteIcon from '../SpriteIcon';
import { Link, Router, useNavigate, useRoutes } from 'react-router-dom';

import van from '../../assets/pictures/van.png';
import { useContext, useState } from 'react';
import {
  anyValue,
  demoUser,
  UserContext,
  UserControlContext,
} from '../../common';
import { CodeField, DateField, PasswordField, TextField } from './InputField';
import { every, set } from 'lodash';

function FieldError({ errors, name }) {
  return (
    <div
      className={clsx(style.fieldError, { [style.errorShow]: errors[name] })}
    >
      {errors[name]}
    </div>
  );
}

function SignInCode({}) {
  const [form, setForm] = useState({
    code: '',
  });

  const [errors, setErrors] = useState({
    code: '',
  });

  const setField = (event) => {
    const { name, value } = event.currentTarget;

    setForm({ ...form, [name]: value });

    delete errors[name];
    setErrors(errors);
  };

  const user = useContext(UserContext);
  const setUser = useContext(UserControlContext);

  let navigate = useNavigate();

  const signInCodeSubmit = () => {
    const newErrors = {};

    if (form.code !== '111111') {
      set(newErrors, 'code', 'Код неверен, перепроверь');

      setTimeout(() => {
        alert('попробуй 111111');
      }, 100);
    }

    if (anyValue(newErrors)) {
      setErrors(newErrors);
      return;
    }

    setUser({ ...user }) || navigate('/');
  };

  return (
    <div className={clsx(style.ctn)}>
      <Link to="/sign/in" className={clsx(style.topNavBtn)}>
        <SpriteIcon name="arrow-left-thicc" width="24" />
        <span>К авторизации</span>
      </Link>

      <div className={clsx(style.cringe, style.titleSignInCode)}>
        Введи код двухфакторки
      </div>

      <CodeField
        className={clsx(style.signInCodeInputRowCode)}
        icon="f-key"
        name="code"
        value={form.code}
        error={errors['code']}
        onChange={setField}
        placeholder={'–'}
      />
      <FieldError name="code" errors={errors} />

      <button
        disabled={anyValue(errors) || form.code.length !== 6}
        type="button"
        className={clsx(style.button, style.signUpButton)}
        onClick={signInCodeSubmit}
      >
        Подтвердить
      </button>
    </div>
  );
}

export default SignInCode;
