import clsx from 'clsx';
import { Link, Route, Routes } from 'react-router-dom';

import style from './SignPage.module.scss';
import SpriteIcon from '../SpriteIcon';
import SignIn from './SignIn';
import SignUp from './SignUp';
import SignPasswordReset from './SignPasswordReset';
import SignPasswordNewSet from './SignPasswordNewSet';
import SignInCode from './SignInCode';

import backgroundVideo from '../../assets/videos/background.mp4';
import backgroundImage from '../../assets/videos/background.jpg';

function SignPage({}) {
  return (
    <div className={clsx(style.ctn, 'App')}>
      <img className={clsx(style.backgroundImage)} src={backgroundImage} />
      <video
        className={clsx(style.backgroundVideo)}
        src={backgroundVideo}
        muted
        autoPlay
        loop
      />

      <Link to="/" className={clsx(style.back)}>
        <div className={clsx(style.backArrow)}>
          <SpriteIcon name="arrow-left" width="32" />
        </div>
        <span className={clsx(style.backText)}>Вернуться на главную</span>
      </Link>

      <div className={clsx(style.modal)}>
        <Routes>
          <Route path="up" element={<SignUp />} />
          <Route path="in" element={<SignIn />} />
          <Route path="in-code" element={<SignInCode />} />
          <Route path="password-reset" element={<SignPasswordReset />} />
          <Route path="password-new-set" element={<SignPasswordNewSet />} />
        </Routes>
      </div>
    </div>
  );
}

export default SignPage;
