import clsx from 'clsx';
import SpriteIcon from './SpriteIcon';

import style from './UserAvatar.module.scss';

function UserAvatar({ imageSrc, className }) {
  return (
    <div className={clsx(style.avatar, className)}>
      <div className={style.avatarCtn}>
        {imageSrc ? (
          <img className={style.avatarImage} src={imageSrc} alt="avatar" />
        ) : (
          <SpriteIcon name="person"  />
        )}
      </div>
    </div>
  );
}

export default UserAvatar;
