import clsx from 'clsx';
import { useContext } from 'react';
import { UserContext } from '../../../common';
import UserAvatar from '../../UserAvatar';
import CommentInput from './CommentInput';

import style from './CommentsTop.module.scss';

function CommentsTop({ className }) {
  const user = useContext(UserContext);

  return (
    <div className={clsx(style.ctn, className)}>
      <UserAvatar className={clsx(style.avatar)} imageSrc={user?.imageSrc} />

      <CommentInput />
    </div>
  );
}

export default CommentsTop;
