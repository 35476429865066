import clsx from 'clsx';
import _ from 'lodash';
import numeral from 'numeral';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getDemoShows } from '../../common';
import { useDebouncedFunction } from '../../hooks/useDebouncedFunction';
import Floating from '../Floating';
import PageLayout from '../PageLayout';
import SpriteIcon from '../SpriteIcon';

import style from './SearchPage.module.scss';
function FilterChip(params) {
  return (
    <div className={clsx(style.filterChip)}>
      <SpriteIcon name={params.icon} width="24" />

      <div className={clsx(style.filterChipText)}>{params.name}</div>

      {params.expand !== false && (
        <div className={clsx(style.filterChipExpandIcon)}>
          <SpriteIcon name="search-expand-arrow-right" width="24" />
        </div>
      )}

      {params.count && (
        <div className={clsx(style.filterChipNumber)}>
          <span>{params.count}</span>
        </div>
      )}
    </div>
  );
}

function FilterSelectMenu(params) {
  return (
    <div
      style={{ width: params.width }}
      className={clsx(style.filterSelectMenu)}
    >
      <div className={clsx(style.filterSelectMenuDrop)}>
        {params.children}

        <button className={clsx(style.filterSelectMenuReset)} type="button">
          Сбросить
        </button>
      </div>
    </div>
  );
}

function FilterDropdown(params) {
  const [width, setWidth] = useState();
  const chipRef = useRef(null);

  return (
    <Floating
      className={clsx()}
      menu={<FilterSelectMenu width={width} children={params.children} />}
      offsetPx={-2}
      placement="bottom"
      children={(open, setOpen) => (
        <label ref={chipRef} className={clsx(style.filterSelectLabel)}>
          <input
            checked={open}
            onChange={(event) => {
              setOpen(event.currentTarget.checked);
              setWidth(chipRef.current.getBoundingClientRect().width);
            }}
            className={clsx(style.filterHiddenCheckbox)}
            type="checkbox"
          />
          <FilterChip
            count={params.count}
            name={params.name}
            icon={params.icon}
          />
        </label>
      )}
    />
  );
}

function FilterSelect(params) {
  return (
    <FilterDropdown
      count={2}
      name={params.name}
      icon={params.icon}
      children={params.variants.map((variant) => {
        return (
          <label className={clsx(style.filterSelectMenuVariant)}>
            <input
              className={clsx(style.filterSelectMenuVariantInput)}
              type="checkbox"
            />
            <div className={clsx(style.filterSelectMenuVariantValue)}>
              {variant}
            </div>
          </label>
        );
      })}
    />
  );
}

function FilterDate(params) {
  //value={year}

  const onBlur = (event) => {
    try {
      const momentDate = moment(date).year(event.currentTarget.value);
      setDate(momentDate.toDate());
      setForm({});
    } catch (error) {
      console.warn(error);
    }
  };

  const onChange = (event) => {
    setForm({ year: event.currentTarget.value });
  };

  return (
    <FilterDropdown
      name={params.name}
      icon={params.icon}
      count={null}
      children={
        <>
          <div className={clsx(style.filterDateField)}>
            <input placeholder=" " type="number" />
            <div>От</div>
          </div>
          <div className={clsx(style.filterDateField)}>
            <input placeholder=" " type="number" />
            <div>До</div>
          </div>
        </>
      }
    />
  );
}

function FilterModalSelect(params) {}

function SearchPage(params) {
  const [searchParams, _setSearchParams] = useSearchParams();
  const setSearchParams = useDebouncedFunction(_setSearchParams, 500);

  const [searchParamsState, setSearchParamsState] = useState({});

  const inputRef = useRef(null);

  const [shows, setShows] = useState([]);
  const setShowsRef = useRef(setShows);
  setShowsRef.current = setShows;

  useEffect(() => {
    const controller = new AbortController();

    var urlToFetch = 'https://httpbin.org/delay/1';

    fetch(urlToFetch, {
      method: 'get',
      signal: controller.signal,
    })
      .then((response) => {
        console.log('setShowsRef');
        setShowsRef.current(getDemoShows((Math.random() * 50 + 1) | 0));
      })
      .catch((error) => {});

    return () => controller.abort();
  }, [searchParams]);

  useEffect(() => {
    const { search, ...params } = searchParamsState;
    setSearchParams({ s: search, p: JSON.stringify(params) });
  }, [searchParamsState]);

  return (
    <PageLayout>
      <div className={clsx(style.ctn)}>
        <div className={clsx(style.searchField)}>
          <input
            ref={inputRef}
            value={searchParamsState.search ?? ''}
            type="text"
            onChange={(event) =>
              setSearchParamsState({ search: event.currentTarget.value })
            }
          />
          <button
            onClick={() => {
              setSearchParamsState({ search: '' });
              inputRef.current.focus();
            }}
            className={clsx(style.searchFieldButtonX)}
          >
            <SpriteIcon name="close-round" width="32" />
          </button>
        </div>

        <div className={clsx(style.filters)}>
          <FilterChip />

          <FilterSelect
            name="Тип"
            variants={['TV', 'OVA', 'ONA', 'Film', 'Special']}
            icon="search-type"
          />
          <FilterDate name="Год" icon="search-year" />

          <FilterSelect
            name="Сезон"
            variants={['Зима', 'Весна', 'Лето', 'Осень']}
            icon="search-season-status"
          />

          <FilterSelect
            name="Рейтинг"
            variants={[
              'G (0+)',
              'PG (6+)',
              'PG-13 (12+)',
              'R-17+ (16+)',
              'R+ (18+)',
            ]}
            icon="search-rating"
          />

          <FilterSelect
            name="Статус"
            variants={['Онгоинг', 'Завершён', 'Анонс']}
            icon="search-season-status"
          />

          <FilterChip />
        </div>

        <div className={clsx(style.resultsCounter)}>Результатов: {numeral(shows.length).format()}</div>

        <div className={clsx(style.actionsCtn)}>1 2 3 4 ... 98 99 100</div>

        <div className={clsx(style.results)}>
          {shows.map((show, index) => {
            return index + ' --';
          })}
        </div>
      </div>
    </PageLayout>
  );
}

export default SearchPage;
