import { useState } from 'react';
import { demoGenre, getDemoGenres } from '../../common';
import GenreCard from './GenreCard';
import style from './Genres.module.scss';
import clsx from 'clsx';
import SpriteIcon from '../SpriteIcon';
import AutoHeight from '../AutoHeight';

function Genres(params) {
  const genres = getDemoGenres(12);

  const [open, setOpen] = useState(false);

  return (
    <div>
      <div className={style.grid}>
        {genres.slice(0, 3).map((genre) => {
          return <GenreCard {...genre} key={genre.id} />;
        })}
      </div>
      <div className={style.switchCtn}>
        <label className={style.switch}>
          <input
            type="checkbox"
            checked={open}
            onChange={({ currentTarget }) => setOpen(currentTarget.checked)}
          />
          <div className={style.switchText}>
            <span className={style.switchClosed}>Показать другие</span>
            <span className={style.switchOpen}>Скрыть</span>
          </div>

          <SpriteIcon width="7" height="12" name="arrowtorightshort" />
        </label>
      </div>

      <AutoHeight className={style.gridMore} open={open}>
        <div className={clsx(style.grid)}>
          {genres.slice(3).map((genre) => {
            return <GenreCard {...genre} key={genre.id} smol />;
          })}
        </div>
      </AutoHeight>

      {/* <div  style={{ height: moreHeight }}>
       
      </div> */}
    </div>
  );
}
export default Genres;
