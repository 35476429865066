import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { ShowContext } from '../../common';

import style from './ShowCover.module.scss';
import SpriteIcon from '../SpriteIcon';

function ShowCover() {
  const { coverImageSrc } = useContext(ShowContext);

  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className={clsx(style.ctn)}
        type="button"
        onClick={() => setOpen(true)}
      >
        <img className={clsx(style.image)} src={coverImageSrc} alt="cover" />
        <div className={clsx(style.icon)}>
          <SpriteIcon name="lupa-large" width="48" />
        </div>
      </button>

      <ShowCoverModal
        open={open}
        onClose={() => setOpen(false)}
        coverImageSrc={coverImageSrc}
      />
    </>
  );
}

function ShowCoverModal({ coverImageSrc, onClose = Function, open }) {
  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : null;

    return () => {
      document.body.style.overflow = undefined;
    };
  }, [open]);

  return ReactDOM.createPortal(
    <div className={clsx(style.modalCtn, { [style.modalCtn_open]: open })}>
      <button
        className={clsx(style.modalClose)}
        type="button"
        onClick={onClose}
      ></button>
      <div className={clsx(style.modalImageCtn)}>
        <img src={coverImageSrc} alt="cover" />
      </div>
    </div>,
    document.querySelector('#root'),
  );
}

export default ShowCover;
