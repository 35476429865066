import style from './ShowCard.module.scss';
import clsx from 'clsx';
import SpriteIcon from './SpriteIcon';
import { useContext, useEffect, useRef, useState } from 'react';
import { ShowContext } from '../common';
import ChipComponent, { EChipVariants } from './Chip';
import { Link } from 'react-router-dom';
import AddToList from './AddToList';
import ShowChips from './ShowChips';
import { createPortal } from 'react-dom';
import { useFloating } from '@floating-ui/react-dom';

function DynamicExpand({
  hover,
  items,
  renderItems,
  moreButtonClassName,
  popupContentClassName,
  itemsContainerClass,
  className,
  renderItemsPopup,
}) {
  const fakeParentRef = useRef(null);

  const [limit, setLimit] = useState(items.length);
  const [openMore, setOpenMore] = useState(false);

  const parentRef = useRef(null);
  const menuRef = useRef(null);

  const { x, y, reference, floating, strategy } = useFloating({
    placement: 'top-end',
    strategy: 'absolute',
    middleware: [],
  });

  useEffect(() => {
    if (!hover) {
      setOpenMore(false);
    }
  }, [hover]);

  useEffect(() => {
    const onClick = (event) => {
      if (menuRef.current?.contains(event.target)) {
        return;
      }

      setOpenMore(false);
    };
    window.addEventListener('pointerdown', onClick);
    return () => {
      window.removeEventListener('pointerdown', onClick);
    };
  }, []);

  useEffect(() => {
    let count = items.length - fakeParentRef.current.children.length;

    const parentBottom = fakeParentRef.current.getBoundingClientRect().bottom;

    for (const child of fakeParentRef.current.children) {
      const childTop = child.getBoundingClientRect().top;

      if (childTop < parentBottom) {
        count += 1;
      }
    }
    console.log(count);

    setLimit(count);
  }, []);

  const itemsComputed =
    limit !== items.length ? items.slice(0, Math.max(0, limit - 1)) : items;

  return (
    <div className={clsx(className, style.dynamic)}>
      <div
        className={clsx(style.itemsPreRender, itemsContainerClass)}
        ref={fakeParentRef}
      >
        {renderItems(items)}
      </div>

      <div className={clsx(style.itemsRender, itemsContainerClass)}>
        {renderItems(itemsComputed, limit !== items.length)}

        {limit !== items.length && (
          <span
            ref={(node) => {
              parentRef.current = node;
              reference(node);
            }}
            onClick={(event) => {
              if (!openMore) {
                setOpenMore(true);
              }
            }}
          >
            <ChipComponent text="..." className={clsx(moreButtonClassName)} />
          </span>
        )}
      </div>

      {openMore &&
        createPortal(
          <div
            className={clsx(style.voicesMore)}
            ref={(node) => {
              menuRef.current = node;
              floating(node);
            }}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <div className={clsx(style.moreContent, popupContentClassName)}>
              {renderItemsPopup(items)}
            </div>
            <SpriteIcon
              className={clsx(style.voicesMoreArrow)}
              name="voices-more"
              width="38"
              height="6"
            />
          </div>,
          document.querySelector('#root'),
        )}
    </div>
  );
}

function ShowCardInnerInfo({ hover, containerRef }) {
  const show = useContext(ShowContext);

  return (
    <div className={style.innerCard}>
      <div className={clsx(style.innerCardBlock, style.innerCardBlocktitles)}>
        <div title={show.title} className={style.infoTitle}>
          {show.title}
        </div>
        <div title={show.titleAlt} className={style.infoTitleAlt}>
          {show.titleAlt}
        </div>
      </div>

      <div className={clsx(style.innerCardBlock, style.innerCardBlockList)}>
        <div className={clsx(style.li)}>
          <span className={clsx(style.liName)}>Студия:</span>
          <Link className={clsx(style.liLink)} to="/ыв">
            {show.studio.text}
          </Link>
        </div>

        <div className={clsx(style.li)}>
          <span className={clsx(style.liName)}>Эпизоды:</span>
          <div className={clsx(style.episode)}>
            <span className={clsx(style.episodeCount, style.liText)}>
              {show.episodes.released}/{show.episodes.count}
            </span>
            <ShowChips className={clsx(style.chipStatus)} fields={['status']} />
          </div>
        </div>

        <DynamicExpand
          className={style.voices}
          hover={hover}
          items={show.cast.voice}
          itemsContainerClass={clsx(style.voicesItems)}
          popupContentClassName={clsx(style.voicesMoreContent)}
          renderItems={(items, isPartial) => {
            return (
              <>
                <span className={clsx(style.liName)}>Озвучили:</span>
                {items.map((user, index, array) => (
                  <span
                    className={clsx(style.liText, style.voice)}
                    key={user.id}
                  >
                    <Link className={clsx(style.liLink)} to="/">
                      {user.name}
                    </Link>

                    {index !== array.length - 1 || isPartial ? ', ' : ''}
                  </span>
                ))}
              </>
            );
          }}
          renderItemsPopup={(items, isPartial) => {
            return items.map((user, index, array) => (
              <span className={clsx(style.liText, style.voice)} key={user.id}>
                <Link className={clsx(style.liLink)} to="/">
                  {user.name}
                </Link>

                {index !== array.length - 1 || isPartial ? ', ' : ''}
              </span>
            ));
          }}
          moreButtonClassName={clsx(style.dotsChip)}
        />

        <div className={clsx(style.chips)}>
          <ShowChips
            className={clsx(style.showChip)}
            fields={['season', 'year', 'type', 'age']}
          />
        </div>
      </div>

      <div className={clsx(style.innerCardBlock, style.innerCardBlockGenres)}>
        <DynamicExpand
          hover={hover}
          items={show.genres}
          popupContentClassName={clsx(style.genresMoreContent)}
          renderItems={(items, isPartial) => {
            return items.map((genre, index, array) => (
              <ChipComponent
                className={clsx(style.genreChip)}
                key={genre.id}
                {...genre}
              />
            ));
          }}
          renderItemsPopup={(items, isPartial) => {
            return items.map((genre, index, array) => (
              <ChipComponent
                className={clsx(style.showChip)}
                key={genre.id}
                {...genre}
              />
            ));
          }}
          itemsContainerClass={clsx(style.genresItems)}
          moreButtonClassName={clsx(style.genreChip)}
        />
      </div>

      <div className={clsx(style.gotoCtn)}>
        <Link className={clsx(style.goto)} to={'/show/' + show.id}>
          <SpriteIcon name="play-fill" width="24" />
          <span>Перейти к тайтлу</span>
        </Link>
      </div>
    </div>
  );
}

function ShowCard({ smol, containerRef }) {
  const show = useContext(ShowContext);

  const [hover, setHover] = useState(false);

  return (
    <div
      hover={`${hover}`}
      className={clsx(style.ctn, { [style.smol]: smol })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={clsx(style.card)}>
        <Link to={'/show/' + show.id}>
          <img className={style.bg} src={show.coverImageSrc} alt="cover" />
          <div className={style.rating}>
            <span>4.1</span>
            <SpriteIcon width="22" height="20" name="star-fill" />
          </div>
        </Link>
        {hover && <ShowCardInnerInfo hover={hover} />}
      </div>

      <div className={clsx(style.addToListCtn)}>
        {hover && (
          <AddToList mode="compact" className={clsx(style.addToList)} />
        )}
      </div>
    </div>
  );
}

export default ShowCard;
