import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import style from './LastUpdates.module.scss';
import Card from '../ShowCard';
import { getDemoShows, ShowContext } from '../../common';
import { Pagination } from 'swiper';
import { useRef } from 'react';

function LastUpdates() {
  const shows = getDemoShows(6 * 3);

  const swiperCtnRef = useRef(null);

  return (
    <div className={style.ctn}>
      <div className={style.swiperCtn} ref={swiperCtnRef}>
        <Swiper
          slidesPerView={6}
          slidesPerGroup={6}
          touchStartPreventDefault={false}
          preventClicksPropagation={false}
          modules={[Pagination]}
          // loopFillGroupWithBlank={true}
          pagination={{
            type: 'bullets',
            clickable: true,
          }}
          breakpoints={{
            1880: {
              slidesPerView: 6,
              slidesPerGroup: 6,
            },
            1610: {
              slidesPerView: 5,
              spaceBetween: 5,
            },
            0: {
              slidesPerView: 4,
              spaceBetween: 4,
            },
          }}
        >
          {shows.map((shows) => {
            return (
              <SwiperSlide key={shows.id}>
                <ShowContext.Provider value={shows}>
                  <div>
                    <Card containerRef={swiperCtnRef} />
                  </div>
                </ShowContext.Provider>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default LastUpdates;
