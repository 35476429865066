import clsx from 'clsx';
import { find } from 'lodash';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ShowContext } from '../../common';
import ChipComponent, { EChipVariants } from '../Chip';

import style from './ShowTags.module.scss';
import SpriteIcon from '../SpriteIcon';

const Chip = (props) => {
  return (
    <ChipComponent {...props} className={clsx(props.className, style.chip)} />
  );
};

function ShowTags({}) {
  const show = useContext(ShowContext);

  const row1 = [
    ['Тип', EChipVariants.TYPE],
    ['Год', EChipVariants.YEAR],
    ['Сезон', EChipVariants.SEASON],
  ].map(([name, variant]) => {
    const text = find(show.chips, { variant })?.text;
    return (
      <div className={clsx(style.li)} key={variant}>
        <span>{name}:</span>
        <div>
          <Chip text={text} variant={variant} />
        </div>
      </div>
    );
  });

  return (
    <div className={clsx(style.ctn)}>
      <div>
        <div className={clsx(style.ul)}>
          <div className={clsx(style.row1)}>{row1}</div>

          <div className={clsx(style.li)}>
            <span>Студия:</span>
            <Link to="/">{show.studio.text}</Link>
          </div>
          <div className={clsx(style.li)}>
            <span>Эпизоды:</span>
            <div className={clsx(style.episode)}>
              <span className={clsx(style.episodeCount)}>
                {show.episodes.released}/{show.episodes.count}
              </span>
              <span className={clsx(style.episodeDuration)}>
                ({show.episodes.durationMinutes} мин.)
              </span>
              <Chip
                text={find(show.chips, { variant: EChipVariants.STATUS })?.text}
                variant={EChipVariants.STATUS}
              />
            </div>
          </div>

          <div className={clsx(style.li)}>
            <span>Жанр:</span>
            <div className={clsx(style.chips)}>
              {show.genres.map((genre) => {
                return (
                  <Chip
                    key={genre.id}
                    text={genre.text}
                    variant={EChipVariants.GENRE}
                  />
                );
              })}
            </div>
          </div>
        </div>

        <h2 className={clsx(style.subtitle)}>Альтернативные названия:</h2>
        <div className={clsx(style.ul)}>
          <div className={clsx(style.li)}>
            <span>По-японски:</span>
            <span className={clsx(style.text)}>その着せ替え人形は恋をする</span>
          </div>
          <div className={clsx(style.li)}>
            <span>По-английски:</span>
            <span className={clsx(style.text)}>My Dress-Up Darling</span>
          </div>
        </div>
      </div>

      <div className={clsx(style.lastBlock)}>
        <div className={clsx(style.li)}>
          <span>Рейтинг: </span>
          <span className={clsx(style.text)}>PG-13</span>
        </div>

        <button className={clsx(style.shikimoriButton)}>
          <span>Страница на Shikimori</span>
          <SpriteIcon name="shikimori" width="24" />
        </button>
      </div>
    </div>
  );
}

export default ShowTags;
