import clsx from 'clsx';
import { useContext } from 'react';
import { ShowContext } from '../../common';

import style from './ShowCast.module.scss';
import UserAvatar from '../UserAvatar';

function UserComponent(props) {
  return (
    <div>
      <UserAvatar {...props} />
    </div>
  );
}

function ShowCast({}) {
  const show = useContext(ShowContext);

  return (
    <div className={clsx(style.ctn)}>
      <span className={clsx(style.header)}>Голоса: 6</span>

      <div className={clsx(style.scroll)}>
        <div className={clsx(style.scrollContent)}>
          {show.cast.voice.map((props) => (
            <UserComponent {...props} key={props.id} />
          ))}
        </div>
      </div>

      <span className={clsx(style.header)}>Работа со звуком: 1</span>

      <div className={clsx(style.scroll)}>
        <div className={clsx(style.scrollContent)}>
          {show.cast.sound.map((props) => (
            <UserComponent {...props} key={props.id} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ShowCast;
