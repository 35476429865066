import clsx from 'clsx';
import Footer from './Footer';
import Header from './header/Header';

import style from './PageLayout.module.scss';

function PageLayout({ children }) {
  return (
    <div className="App">
      <Header />
      {children}
      <Footer />
    </div>
  );
}

export default PageLayout;
