import clsx from 'clsx';

import style from './Comments.module.scss';
import CommentsBlock from './CommentsBlock';
import CommentsTop from './CommentsTop';

function Comments({ className }) {
  return (
    <>
      <CommentsTop className={clsx(className, style.top)} />

      <CommentsBlock className={clsx(className, style.block)} />
    </>
  );
}

export default Comments;
