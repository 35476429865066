import clsx from 'clsx';

import style from './SignIn.module.scss';

import SpriteIcon from '../SpriteIcon';

import { useRef, useState } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { clamp } from 'lodash';
import ReactCodeInput from '../ReactCodeInput';
moment.locale('ru');

export function TextField({ icon, className, inputRef, error, ...params }) {
  return (
    <div
      className={clsx(style.field, className, { [style.fieldIsError]: error })}
    >
      {icon && <SpriteIcon name={icon} width={'24'} />}
      <div className={clsx(style.fieldInputContainer)}>
        <input ref={inputRef} {...params} />
      </div>
    </div>
  );
}

export function DateField({ icon, value, className, error, ...params }) {
  const defaultDate = () => moment().subtract(12, 'years').toDate();

  const [date, setDate] = useState(value ?? defaultDate());

  const [form, setForm] = useState({
    day: null,
    month: null,
    year: null,
  });

  const day = form.day ?? moment(date).format('DD');
  const month = form.month ?? moment(date).format('MMMM');
  const year = form.year ?? moment(date).format('YYYY');

  const months = useRef(moment.months());

  const [monthFocus, setMonthFocus] = useState(false);
  const monthRef = useRef(null);

  return (
    <div className={clsx(className)}>
      <div className={clsx(style.dateField)}>
        <SpriteIcon name="f-date" width="24" />

        <div className={clsx(style.dateTitle)}>Дата рождения:</div>
      </div>

      <div className={clsx(style.dateFieldInputs)}>
        <TextField
          className={clsx(style.dateFieldDay)}
          value={day}
          onBlur={(event) => {
            try {
              const daysInMonth = moment(date).daysInMonth();
              const newDate = clamp(+event.currentTarget.value, 1, daysInMonth);

              const momentDate = moment(date).date(newDate);
              setDate(momentDate.toDate());
              setForm({});
            } catch (error) {
              console.warn(error);
            }
          }}
          onChange={(event) => {
            setForm({ day: event.currentTarget.value });
          }}
        />

        <div className={clsx(style.dateFieldMonth)}>
          <div
            className={clsx(style.field, style.dateFieldMonthCtn, {
              [style.fieldIsError]: error,
            })}
          >
            <div className={clsx(style.fieldInputContainer)}>
              <input
                ref={monthRef}
                value={month}
                onFocus={() => {
                  setMonthFocus(true);
                }}
                onBlur={(event) => {
                  setMonthFocus(false);
                  if (event.currentTarget.forceBlurred) return;

                  try {
                    const momentDate = moment(date).month(
                      event.currentTarget.value,
                    );
                    setDate(momentDate.toDate());
                    setForm({});
                  } catch (error) {
                    console.warn(error);
                  }
                }}
                onChange={(event) => {
                  setForm({ month: event.currentTarget.value });
                }}
              />
            </div>
          </div>

          {monthFocus && (
            <div
              className={clsx(style.dateFieldMonthDrop)}
              onMouseDown={(event) => {
                event.preventDefault();
              }}
            >
              {months.current.map((monthName, index) => {
                return (
                  <button
                    className={clsx(style.monthDropButton)}
                    key={index}
                    type="button"
                    onClick={(event) => {
                      const momentDate = moment(date).month(monthName);
                      setDate(momentDate.toDate());
                      setForm({});

                      monthRef.current.forceBlurred = true;
                      monthRef.current.blur();
                      monthRef.current.forceBlurred = false;
                    }}
                  >
                    {monthName}
                  </button>
                );
              })}
            </div>
          )}
        </div>

        <TextField
          className={clsx(style.dateFieldYear)}
          value={year}
          onBlur={(event) => {
            try {
              const momentDate = moment(date).year(event.currentTarget.value);
              setDate(momentDate.toDate());
              setForm({});
            } catch (error) {
              console.warn(error);
            }
          }}
          onChange={(event) => {
            setForm({ year: event.currentTarget.value });
          }}
        />
      </div>
    </div>
  );
}

export function PasswordField({
  show: showProp,
  setShow: setShowParam,
  className,
  error,
  ...params
}) {
  const [showLocal, setShow] = useState(false);

  const methodSetShow = (value) => {
    setShow(value);
    setShowParam?.(value);
  };

  const showComputed = showProp ?? showLocal;

  return (
    <div
      className={clsx(style.field, className, {
        [style.fieldIsError]: error,
      })}
    >
      <SpriteIcon
        name={showProp === undefined ? 'f-lock' : 'f-lock-fill'}
        width="24"
      />
      <div className={clsx(style.fieldInputContainer)}>
        <input
          placeholder="Пароль"
          type={showComputed ? 'text' : 'password'}
          {...params}
        />

        {showProp === undefined && (
          <button
            className={clsx(style.fieldControlIcon)}
            dom-show={`${showComputed}`}
            type="button"
            onClick={() => methodSetShow(!showComputed)}
          >
            {showComputed ? (
              <SpriteIcon name="f-view" width="24" />
            ) : (
              <SpriteIcon name="f-view-hide" width="24" />
            )}
          </button>
        )}
      </div>
    </div>
  );
}

export function CodeField({ className, error, icon, ...params }) {
  return (
    <div
      className={clsx(style.field, className, {
        [style.fieldIsError]: error,
      })}
    >
      <SpriteIcon name={icon} width="24" />
      <div className={clsx(style.fieldInputContainer)}>
        <ReactCodeInput
          className={clsx(style.codeInput)}
          type="text"
          fields={6}
          {...params}
        />
      </div>
    </div>
  );
}
