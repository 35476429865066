import clsx from 'clsx';
import { demoComments } from '../../../common';
import Comment from './Comment';
import CommentRoot from './CommentRoot';

import style from './CommentsBlock.module.scss';

function CommentsBlock({ className }) {
  const comments = demoComments;

  return (
    <div className={clsx(style.ctn, className)}>
      <div className={clsx(style.head)}>Комментарии (69 420)</div>
      <hr />
      <div className={clsx(style.comments)}>
        {comments.map((props) => {
          return <CommentRoot className={clsx(style.comment)} {...props} key={props.id} />;
        })}
      </div>
    </div>
  );
}

export default CommentsBlock;
