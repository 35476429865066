import clsx from 'clsx';

import style from './SignIn.module.scss';

import SpriteIcon from '../SpriteIcon';
import { Link, Router, useNavigate, useRoutes } from 'react-router-dom';

import van from '../../assets/pictures/van.png';
import { useContext, useState } from 'react';
import { anyValue, demoUser, UserControlContext } from '../../common';
import { DateField, PasswordField, TextField } from './InputField';
import { every, set } from 'lodash';

function FieldError({ errors, name }) {
  return (
    <div
      className={clsx(style.fieldError, { [style.errorShow]: errors[name] })}
    >
      {errors[name]}
    </div>
  );
}

function SignUp({}) {
  const [passwordsShow, setPasswordsShow] = useState(false);

  const [form, setForm] = useState({
    email: '',
    name: '',
    birthday: '',
    password: '',
    passwordConfirm: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    name: '',
    birthday: '',
    password: '',
    passwordConfirm: '',
  });

  const setField = (event) => {
    const { name, value } = event.currentTarget;

    setForm({ ...form, [name]: value });

    delete errors[name];
    setErrors(errors);
  };

  const setUser = useContext(UserControlContext);

  let navigate = useNavigate();

  const signUp = () => {
    const newErrors = {};

    if (!form.name) {
      set(newErrors, 'name', 'Введи никнейм');
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email)) {
      set(newErrors, 'email', 'Это не Email. Это непонятно что');
    }

    if (!form.email) {
      set(newErrors, 'email', 'Введи эл. почту');
    }

    if (
      form.password &&
      form.passwordConfirm &&
      form.password !== form.passwordConfirm
    ) {
      set(newErrors, 'password', 'Пароли не совпадают');
      set(newErrors, 'passwordConfirm', 'Пароли не совпадают');
    }

    if (!form.password) {
      set(newErrors, 'password', 'Введи пароль');
    }

    if (!form.passwordConfirm) {
      set(newErrors, 'passwordConfirm', 'Введи пароль');
    }

    if (anyValue(newErrors)) {
      setErrors(newErrors);
      return;
    }

    form.name // just for demo
      ? setUser({ ...demoUser, name: form.name }) || navigate('/')
      : setErrors({
          // ошибки можно возвращать в ответе с сервера чтобы не дублировать логиту здесь
          email: 'Никнейм занят. Выбери другой, например: Ludoed1337',
          name: 'Никнейм занят. Выбери другой, например: Ludoed1337',
        });
  };

  return (
    <div className={clsx(style.ctn)}>
      <Link to="/sign/in" className={clsx(style.topNavBtn)}>
        <SpriteIcon name="arrow-left-thicc" width="24" />
        <span>К авторизации</span>
      </Link>

      <div className={clsx(style.cringe, style.titleSignUp)}>
        Зарегистрируйся ради <br /> великого Airis
        <img src={van} alt="van" width="47" />
      </div>

      <TextField
        className={clsx(style.signUpInputRowEmail)}
        icon="f-message"
        placeholder="Email"
        name="email"
        value={form.email}
        onChange={setField}
        error={errors['email']}
      />
      <FieldError name="email" errors={errors} />

      <TextField
        className={clsx(style.signUpInputRowName)}
        icon="f-user"
        placeholder="Никнейм"
        name="name"
        value={form.name}
        onChange={setField}
        error={errors['name']}
      />
      <FieldError name="name" errors={errors} />

      <DateField className={clsx(style.signUpInputRowDate)} />

      <div className={clsx(style.warning, { [style.errorShow]: errors.name })}>
        Указать дату рождения можно только единожды!
      </div>

      <PasswordField
        setShow={setPasswordsShow}
        value={form.password}
        name="password"
        placeholder="Пароль"
        onChange={setField}
        className={clsx(style.signUpInputRowPassword)}
        error={errors['password']}
      />

      <PasswordField
        setShow={setPasswordsShow}
        show={passwordsShow}
        value={form.passwordConfirm}
        name="passwordConfirm"
        placeholder="Повтори пароль"
        onChange={setField}
        className={clsx(style.signUpInputRowPasswordConfirm)}
        error={errors['passwordConfirm']}
      />
      {errors.password ? (
        <FieldError name="password" errors={errors} />
      ) : (
        <FieldError name="passwordConfirm" errors={errors} />
      )}

      <div className={clsx(style.captha)}>тут будет капча</div>

      <button
        disabled={anyValue(errors)}
        type="button"
        className={clsx(style.button, style.signUpButton)}
        onClick={signUp}
      >
        Создать аккаунт
      </button>

      <Link to="/" className={clsx(style.doc)}>
        Политика конфиденциальности
      </Link>
    </div>
  );
}

export default SignUp;
