import style from './Header.module.scss';
import SearchBar from './SearchBar';
import Notification from './Notification';
import More from './More';
import clsx from 'clsx';
import { Link, NavLink, useLocation } from 'react-router-dom';
import SpriteIcon from '../SpriteIcon';
import UserAvatar from '../UserAvatar';
import HeaderProfileMenu from './HeaderProfileMenu';
import { useContext } from 'react';
import { UserContext } from '../../common';

function LogoMainPage() {
  return (
    <NavLink
      to="/"
      className={(state) => {
        return clsx(style.logoSvg, {
          [style.logoSvg_passive]: !state.isActive,
        });
      }}
    >
      <SpriteIcon width="206" height="30" name="logo2" />
    </NavLink>
  );
}

function Header() {
  const user = useContext(UserContext);

  return (
    <div className={style.Header}>
      <LogoMainPage />

      <div className={style.menuButtons}>
        {[
          { icon: 'releases', text: 'Релизы' },
          { icon: 'room-list', text: 'Список комнат' },
          { icon: 'schedule', text: 'Расписание' },
        ].map(({ icon, text }, index) => (
          <button key={index} className={style.menuButton}>
            <SpriteIcon width="24" name={icon} />
            <span>{text}</span>
          </button>
        ))}

        <More className={clsx(style.menuMore, style.menuButton)} />
      </div>

      <div className={style.group}>
        <SearchBar className={style.searchBar} />

        {!!user && <Notification className={style.notification} />}
      </div>

      <HeaderProfileMenu className={style.avatar} />
    </div>
  );
}

export default Header;
