import style from './Chip.module.scss';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

export const EChipVariants = {
  TYPE: 'TYPE',
  SEASON: 'SEASON',
  YEAR: 'YEAR',
  STATUS: 'STATUS',
  GENRE: 'GENRE',
};

function ChipComponent({ text, name, className, onClick, to, onPointerDown }) {
  const Base = to ? Link : 'button';

  return (
    <Base
      type="button"
      onClick={onClick}
      to={to}
      className={clsx(className, style.chip)}
      onPointerDown={onPointerDown}
    >
      {text || name}
    </Base>
  );
}

export default ChipComponent;
