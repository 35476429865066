import clsx from 'clsx';
import { useRef, useState } from 'react';
import SpriteIcon from '../../SpriteIcon';

import style from './CommentInput.module.scss';

function CommentInput({ className }) {
  const [commentValue, setCommentValue] = useState('');
  const textareaRef = useRef(null);

  const onCommentValueChange = (event) => {
    setCommentValue(event.currentTarget.value);
  };

  const placeholder = 'Пиши сюды...';

  return (
    <div className={clsx(style.ctn, className)}>
      <label className={clsx(style.field)}>
        <textarea
          rows="1"
          ref={textareaRef}
          className={clsx(style.input)}
          value={commentValue}
          onChange={onCommentValueChange}
          placeholder={placeholder}
          key="textarea"
        />
        <div
          key="text"
          dangerouslySetInnerHTML={{ __html: commentValue + '\n' }}
        />
      </label>

      <button className={clsx(style.send)} type="button">
        <span>Отправить</span>

        <SpriteIcon name="send" width="24" />
      </button>
    </div>
  );
}

export default CommentInput;
