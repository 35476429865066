import clsx from 'clsx';
import { find } from 'lodash';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ShowContext } from '../../common';
import ChipComponent, { EChipVariants } from '../Chip';

import style from './ShowCardSearchResult.module.scss';

function ShowCardSearchResult({ className }) {
  const show = useContext(ShowContext);

  // <ShowChips fields={['type', 'year', 'season']}/>

  const row1 = [
    ['Тип', EChipVariants.TYPE],
    ['Год', EChipVariants.YEAR],
    ['Сезон', EChipVariants.SEASON],
  ].map(([name, variant], index) => {
    const text = find(show.chips, { variant })?.text;
    return (
      <div className={clsx(style.li)} key={variant}>
        <span className={clsx(style.liName)}>{name}:</span>
        <div>
          <ChipComponent text={text} variant={variant} />
        </div>
      </div>
    );
  });

  const showUrl = '/show/' + show.id;

  return (
    <div className={clsx(style.ctn, className)}>
      <Link className={clsx(style.showLink)} to={showUrl}>
        <img
          className={clsx(style.poster)}
          src={show.coverImageSrc}
          alt="poster"
        />
      </Link>

      <div className={clsx(style.info)}>
        <Link to={showUrl} className={clsx(style.name)}>
          <span className={clsx(style.nameSpan)}>
            {' '}
            {show.title} / {show.titleAlt}
          </span>
        </Link>

        <div className={clsx(style.row1)}>{row1}</div>

        <div className={clsx(style.li)}>
          <span className={clsx(style.liName)}>Студия:</span>
          <Link className={clsx(style.liLink)} to="/">
            {show.studio.text}
          </Link>
          <div className={clsx(style.episode)}>
            <span className={clsx(style.episodeCount, style.liText)}>
              {show.episodes.released}/{show.episodes.count}
            </span>
            <ChipComponent
              className={clsx(style.chipStatus)}
              text={find(show.chips, { variant: EChipVariants.STATUS })?.text}
              variant={EChipVariants.STATUS}
            />
          </div>
        </div>

        <div className={clsx(style.li)}>
          <span className={clsx(style.liName)}>Жанр:</span>
          <div className={clsx(style.chips)}>
            {show.genres.map((genre) => {
              return (
                <ChipComponent
                  key={genre.id}
                  name={genre.name}
                  variant={EChipVariants.GENRE}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowCardSearchResult;
