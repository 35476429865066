import clsx from 'clsx';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ShowContext } from '../../common';
import { EChipVariants } from '../Chip';

import style from './RelateItem.module.scss';

function TagComponent({ text }) {
  return <div className={clsx(style.tag)}>{text}</div>;
}

function RelateItem({ className }) {
  const show = useContext(ShowContext);

  const tags = show.chips.filter((chip) => {
    return [EChipVariants.TYPE, EChipVariants.YEAR].includes(chip.variant);
  });

  const episodesTag = {
    text: show.episodes.released + '/' + show.episodes.count,
  };
  tags.splice(show.chips.length - 1, 0, episodesTag);

  return (
    <Link to={'/show/' + show.id} className={clsx(style.ctn, className)}>
      <img className={clsx(style.bg)} src={show.listItemImageSrc} alt="" />
      <div className={clsx(style.bgOverlay)}></div>
      <div className={clsx(style.num)}>221</div>
      <div className={clsx(style.name)}>
        <div className={clsx(style.namePrimary)}>{show.title}</div>
        <hr className={clsx(style.nameHr)} />
        <div className={clsx(style.nameSecondary)}>{show.titleAlt}</div>
      </div>

      <div className={clsx(style.tags)}>
        {tags.map((tag, index) => {
          return <TagComponent key={index} text={tag.text} />;
        })}
      </div>
    </Link>
  );
}
export default RelateItem;
