import clsx from 'clsx';

import style from './ShowPage.module.scss';
import Player from '../player/Player';
import ShowInfo from './ShowInfo';
import { demoShow, ShowContext } from '../../common';
import Comments from './comments/Comments';
import PageLayout from '../PageLayout';

function ShowPage({ className }) {
  const show = demoShow;

  return (
    <PageLayout>
      <ShowContext.Provider value={show}>
        <div className={clsx(style.page, className)}>
          <Player />

          <ShowInfo className={clsx(style.info)} />

          <Comments className={clsx(style.comments)} />
        </div>
      </ShowContext.Provider>
    </PageLayout>
  );
}

export default ShowPage;
