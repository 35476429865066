import clsx from 'clsx';
import { useState } from 'react';
import AutoHeight from '../../AutoHeight';
import SpriteIcon from '../../SpriteIcon';
import UserAvatar from '../../UserAvatar';
import UserBadge from '../../UserBadge';

import style from './Comment.module.scss';
import CommentInput from './CommentInput';

function Comment({ author, content, rating, className }) {
  const [open, setOpen] = useState(false);
  const [textHeight, setTextHeight] = useState(null);
  const [replyOpen, setReplyOpen] = useState(false);
  

  return (
    <div className={clsx(style.ctn, className)}>
      <UserAvatar {...author} className={clsx(style.avatar)} />
      <div className={clsx(style.body)}>
        <div className={clsx(style.head)}>
          <div className={clsx(style.authorName)}>{author.name}</div>
          <UserBadge {...author} className={clsx(style.authorBadge)} />

          <button type="button" className={clsx(style.reply)}>
            <SpriteIcon name="reply" width="24" />
            <span>Airis</span>
          </button>

          <div className={clsx(style.date)}>51 минуту назад</div>

          <div className={clsx(style.editStatus)}>
            <span>(</span>
            <SpriteIcon name="edit" width="20" />
            <span>Ред.)</span>
          </div>
        </div>

        <div className={clsx(style.mid)}>
          <div className={clsx(style.contentCtn)}>
            <AutoHeight
              open={open}
              onContentHeight={setTextHeight}
              minMaxHeight={87}
              useMaxHeight
            >
              <div className={clsx(style.content)}>{content}</div>
            </AutoHeight>

            {textHeight > 87 && (
              <label className={clsx(style.showFull)}>
                <input
                  checked={open}
                  onChange={() => setOpen(!open)}
                  type="checkbox"
                />
                <span>(показать полностью)</span>
                <span>(показать не полностью)</span>
              </label>
            )}
          </div>

          <div className={clsx(style.rating)}>
            <label className={clsx(style.ratingLike)}>
              <input type="checkbox" />
              <SpriteIcon name="like" width="24" height="22" />
              <span>{rating.positive}</span>
            </label>
            <label className={clsx(style.ratingDislike)}>
              <input type="checkbox" />
              <SpriteIcon name="dislike" width="24" height="22" />
              <span>{rating.negative}</span>
            </label>
          </div>
        </div>

        <div className={clsx(style.actions)}>
          {!replyOpen ? (
            <button
              onClick={() => setReplyOpen(true)}
              type="button"
              className={clsx(style.actionAnswer)}
            >
              ОТВЕТИТЬ
            </button>
          ) : (
            <button
              onClick={() => setReplyOpen(false)}
              type="button"
              className={clsx(style.actionCancel)}
            >
              ОТМЕНА
            </button>
          )}
          <div className={clsx(style.actionDots)}>
            <input type="checkbox" className={clsx(style.actionDotsInput)} />
            <div type="button">
              <SpriteIcon name="dots" width="24" />
            </div>
            <div className={clsx(style.actionDotsMenu)}>
              <button type="button" className={clsx(style.actionWarning)}>
                <SpriteIcon name="warning" width="24" />
                <span>Пожаловаться</span>
              </button>
              <button type="button" className={clsx(style.actionEdit)}>
                <SpriteIcon name="edit" width="24" />
                <span>Редактировать</span>
              </button>
              <button type="button" className={clsx(style.actionDelete)}>
                <SpriteIcon name="02" width="24" />
                <span>Удалить</span>
              </button>
              <button type="button" className={clsx(style.actionWarn)}>
                <SpriteIcon name="info" width="24" />
                <span>Дать предупреждение</span>
              </button>
              <button type="button" className={clsx(style.actionBan)}>
                <SpriteIcon name="no-texture" width="24" />
                <span>БАН</span>
              </button>
            </div>
          </div>
        </div>
        {replyOpen && <CommentInput className={clsx(style.replyInput)} />}
      </div>
    </div>
  );
}

export default Comment;
