import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import style from './Banner.module.scss';

import { createContext, useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import ChipComponent from '../Chip';
import { demoShow, getDemoShows, ShowContext } from '../../common';
import AddToList from '../AddToList';
import useLocalStorage from 'react-use-localstorage';
import SpriteIcon from '../SpriteIcon';
import { Link } from 'react-router-dom';
import ShowChips from '../ShowChips';

const PausePlayContext = createContext([
  null,
  () => console.warn('not defined'),
]);

const Slide = () => {
  const show = useContext(ShowContext);

  const [play, setPlay, autoplay] = useContext(PausePlayContext);

  const onPausePlay = (pause) => {
    setPlay(!pause);
  };

  const videoRef = useRef(null);

  return (
    <div className={style.slide}>
      <div className={style.slideVideoCtn}>
        {autoplay ? (
          <video
            ref={videoRef}
            className={style.slidePrevewMediaElement}
            autoPlay
            muted
            loop
            src={show.previewVideoSrc}
          ></video>
        ) : (
          <img
            className={style.slidePrevewMediaElement}
            src={show.previewVideoImageSrc}
          />
        )}

        <div className={style.slideVideoOverlay}></div>
      </div>

      <div className={style.slideTitle}>{show.title}</div>
      <div className={style.chips}>
        {show.genres.map((chip, index) => (
          <ChipComponent key={index} {...chip} />
        ))}
      </div>
      <div className={style.chips}>
        <ShowChips fields={['type', 'season', 'year', 'status']} />
      </div>

      <AddToList className={style.addToList} onOpenClose={onPausePlay} />

      <Link className={style.goToButton} to={'/show/' + show.id}>
        Перейти к тайтлу
      </Link>
    </div>
  );
};

function Banner() {
  const slides = getDemoShows(3);

  const [swiper, setSwiper] = useState(null);
  const [play, setPlay] = useState(true);
  const [autoplayStr, setAutoplay] = useLocalStorage('banner-autoplay', 'true');

  const autoplay = autoplayStr !== 'false' && play;

  useEffect(() => {
    if (!swiper || swiper.destroyed) return;
    if (autoplay) {
      swiper.autoplay.start();
    } else {
      swiper.autoplay.stop();
    }
  }, [autoplay, swiper]);

  return (
    <div className={clsx(style.ctn, { [style.swiperAutoplay]: autoplay })}>
      <PausePlayContext.Provider value={[play, setPlay, autoplay]}>
        <Swiper
          modules={[Autoplay, Navigation, Pagination]}
          //  navigation
          pagination={{
            type: 'bullets',
            clickable: true,
          }}
          allowTouchMove={false}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          onInit={(swiper) => {
            setSwiper(swiper);
          }}
        >
          {slides.map((slide, index) => {
            return (
              <SwiperSlide key={slide.id}>
                <ShowContext.Provider value={slide}>
                  <Slide />
                </ShowContext.Provider>
              </SwiperSlide>
            );
          })}
          <span slot="container-end">
            <label className={style.autoSwitch}>
              <input
                type="checkbox"
                checked={autoplay}
                onChange={(event) => {
                  setAutoplay(`${event.currentTarget.checked}`);
                  setPlay(event.currentTarget.checked);
                }}
              />
              <div className={style.autoSwitchBg}></div>
              <SpriteIcon
                className={style.autoSwitchPlay}
                width="24"
                name="autoplay"
              />

              <SpriteIcon
                className={style.autoSwitchPause}
                width="24"
                name="autoplay-pause"
              />
              <span>Авто</span>
            </label>

            <a
              href="#mainContent"
              className={style.scrollBotButton}
              onClick={(event) => {
                event.preventDefault();

                document.querySelector(`[name=mainContent]`).scrollIntoView({
                  block: 'start',
                  behavior: 'smooth',
                });
              }}
            >
              <span>Последние обновления</span>
              <SpriteIcon width="131" height="26" name="arrowtobotwide" />
            </a>
          </span>
        </Swiper>
      </PausePlayContext.Provider>
    </div>
  );
}

export default Banner;
