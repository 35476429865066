import clsx from 'clsx';

import style from './UserBadge.module.scss';
import { EUsersRoles } from './../common';
import SpriteIcon from './SpriteIcon';

function UserBadge({ className, role }) {
  const roles = {
    [EUsersRoles.VIEWER]: {
      name: 'Зритель',
      icon: 'human',
      color: '#79AFFF',
    },
  };

  const badge = roles[role] ?? roles[EUsersRoles.VIEWER];
  return (
    <div
      className={clsx(style.badge, className)}
      style={{ '--fill': badge.color }}
    >
      <SpriteIcon name={badge.icon} width="24" />
      <span>{badge.name}</span>
    </div>
  );
}

export default UserBadge;
