import style from './Notification.module.scss';
import SpriteIcon from '../SpriteIcon';
import clsx from 'clsx';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { demoNotifications } from '../../common';
import moment from 'moment';
import 'moment/locale/ru';
import { some } from 'lodash';
import Floating from '../Floating';

moment.locale('ru');

function Tab({ name, id, tab, setTab }) {
  return (
    <button
      onClick={() => setTab(id)}
      checked-dom={`${tab === id}`}
      className={clsx(style.tab)}
    >
      {name}
    </button>
  );
}

function Tabs({ tabs, tab, setTab }) {
  return (
    <div className={clsx(style.tabs)}>
      {tabs.map((tabProps) => {
        return (
          <Tab {...tabProps} key={tabProps.id} tab={tab} setTab={setTab} />
        );
      })}
    </div>
  );
}

function NotificationItemBase({
  text,
  details,
  onClose,
  createdAt,
  visual,
  url,
  name,
  viewed,
}) {
  const ContainerComponent = url ? Link : 'div';

  return (
    <ContainerComponent
      to={url ? url : undefined}
      className={clsx(style.notification)}
      viewed={`${viewed}`}
    >
      <div className={clsx(style.notificationVisual)}>
        <div>{visual}</div>
      </div>

      <div className={clsx(style.notificationBody)}>
        <div className={clsx(style.notificationName)}>{name}</div>

        {text && <div className={clsx(style.notificationContent)}>{text}</div>}

        <div className={clsx(style.notificationTime)}>
          {moment(createdAt).fromNow()}
        </div>
      </div>

      <button
        onClick={(event) => {
          event.preventDefault();
          onClose();
        }}
        className={clsx(style.notificationClose)}
        type="button"
      >
        <SpriteIcon name={'x'} width="24" />
      </button>
    </ContainerComponent>
  );
}

function NotificationItem(notification) {
  const notificationClose = () => {
    // todo smthng
  };

  switch (notification.type) {
    case 'achievement-received':
      return (
        <NotificationItemBase
          text={
            <Link
              to={`ac/${notification.achievementId}`}
              className={clsx(style.achievementName)}
            >
              {notification.description}
            </Link>
          }
          visual={<img src={notification.image} width="70" height="70" />}
          viewed={notification.viewed}
          name={notification.name}
          onClose={notificationClose}
        />
      );
    case 'general':
      return (
        <NotificationItemBase
          text={notification.description}
          visual={<SpriteIcon name={notification.icon} width="32" />}
          url={notification.url}
          viewed={notification.viewed}
          name={notification.name}
          onClose={notificationClose}
        />
      );
    case 'show-update':
      const showTo = '/show/' + notification.show.id;
      return (
        <NotificationItemBase
          text={
            <span to={showTo} className={clsx(style.notificationShowName)}>
              {notification.show.title}
            </span>
          }
          visual={
            <img
              className={clsx(style.notificationImage)}
              src={notification.show.coverImageSrc}
            />
          }
          url={showTo}
          viewed={notification.viewed}
          name={notification.name}
          onClose={notificationClose}
        />
      );

    case 'comment-reply':
      return (
        <NotificationItemBase
          visual={<SpriteIcon name="chat-fill" width="32" />}
          // url={showTo}
          viewed={notification.viewed}
          name={
            <span>
              <Link to={'/'}>{notification.replyComment.author.name}</Link>
              {' ответил:'}
            </span>
          }
          onClose={notificationClose}
          text={
            <div className={clsx(style.notificationReply)}>
              <div className={clsx(style.notificationReplyContent)}>
                {notification.replyComment.content}
              </div>

              <div className={clsx(style.notificationReplyReplied)}>
                <div className={clsx(style.notificationReplyRepliedCmt)}>
                  {'на комментарий: '}
                </div>

                <Link to={'/'}>{notification.originalComment.content}</Link>
              </div>
            </div>
          }
        />
      );
    case 'comment-rating':
      return (
        <NotificationItemBase
          visual={<SpriteIcon name="like-fill" width="32" />}
          // url={showTo}
          viewed={notification.viewed}
          name={notification.name}
          onClose={notificationClose}
          text={
            <div className={clsx(style.notificationReply)}>
              <Link
                to={'/'}
                className={clsx(
                  style.notificationReplyContent,
                  style.notificationComment,
                )}
              >
                {notification.comment.content}
              </Link>
            </div>
          }
        />
      );

    default:
      break;
  }
}

function NotificationMenu({ notifications }) {
  const tabs = [
    { name: 'Все', id: 'all' },
    { name: 'Комментарии', id: 'comment' },
    { name: 'Новые серии', id: 'show' },
    { name: 'Важные', id: 'general' },
  ];

  const [tab, setTab] = useState(tabs[0].id);

  const notificationsComputed = notifications.filter(
    (note) => tab === 'all' || note.tab === tab,
  );

  return (
    <div className={clsx(style.menu)}>
      <Tabs tabs={tabs} tab={tab} setTab={setTab} />

      <button type="button" className={clsx(style.markRead)}>
        <SpriteIcon name="status-list" width="24" />
        <span>пометить всё как прочитанное</span>
      </button>

      <div className={clsx(style.notifications)}>
        <div className={clsx(style.notificationsContent)}>
          {notificationsComputed.map((notification) => {
            return <NotificationItem {...notification} key={notification.id} />;
          })}
        </div>
      </div>
    </div>
  );
}

function Notification({ className }) {
  const notifications = demoNotifications;

  return (
    <Floating
      offsetPx={12}
      className={clsx(className, style.ctn)}
      menu={<NotificationMenu notifications={notifications} />}
      children={(open, setOpen) => (
        <button
          onClick={() => setOpen(!open)}
          type="button"
          className={style.button}
        >
          <SpriteIcon width="32" name="bell-pin" />

          {some(notifications, { viewed: false }) && (
            <div className={style.buttonStatus}></div>
          )}
        </button>
      )}
    />
  );
}

export default Notification;
