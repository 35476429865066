import clsx from 'clsx';
import { useContext } from 'react';
import { ShowContext } from '../common';
import ChipComponent from './Chip';

import style from './ShowChips.module.scss';

function ShowChips({
  fields,
  CustomChipComponent = ChipComponent,
  className,
  ...props
}) {
  const show = useContext(ShowContext);

  const result = [];

  fields.forEach((field, index) => {
    if (!show[field]) return;

    result.push(
      <CustomChipComponent
        key={field}
        className={clsx(className, style[field])}
        text={show[field]}
        {...props}
      />,
    );
  });

  return result;
}

export default ShowChips;
