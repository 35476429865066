import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import { demoUser, UserContext, UserControlContext } from './common';
import MainPage from './components/main-page/MainPage';
import SerachPage from './components/search-page/SearchPage';
import ShowPage from './components/show-page/ShowPage';
import SignPage from './components/sign-page/SignPage';

function App() {
  const [user, setUser] = useState(null);

  return (
    <UserControlContext.Provider value={setUser}>
      <UserContext.Provider value={user}>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/show/:id" element={<ShowPage />} />
          <Route path="/sign/*" element={<SignPage />} />
          <Route path="/search" element={<SerachPage />} />
        </Routes>
      </UserContext.Provider>
    </UserControlContext.Provider>
  );
}

export default App;
