import clsx from 'clsx';
import { Link } from 'react-router-dom';
import style from './GenreCard.module.scss';

const GenreCard = ({ imageSrc, name, views, cardTextShadowColor, smol }) => {
  return (
    <Link
      to="/"
      className={clsx(style.genre, { [style.smol]: smol })}
      style={{ '--cardTextShadowColor': cardTextShadowColor }}
    >
      <div
        className={clsx(style.holoImage)}
        style={{ backgroundImage: `url(${imageSrc})` }}
      />

      <div className={clsx(style.background)}>
        <img className={clsx(style.image)} src={imageSrc} alt="genre image" />
        <div className={clsx(style.imageOverlay)}></div>
      </div>

      <div className={clsx(style.name)}>{name}</div>
      <div className={clsx(style.views)}>
        {views.toLocaleString()} просмотров
      </div>
    </Link>
  );
};

export default GenreCard;
