import Banner from './Banner';
import LastUpdates from './LastUpdates';
import style from './MainPage.module.scss';
import clsx from 'clsx';
import Genres from './Genres';
import News from './News';
import PageLayout from '../PageLayout';

function MainPage() {
  return (
    <PageLayout>
      <div className={clsx(style.page)}>
        <Banner />
        <a name="mainContent"></a>
        <div className={clsx(style.title, style.lastupdts)}>
          Последние обновления
        </div>
        <LastUpdates />
        <div className={clsx(style.title, style.popularReleases)}>
          Популярные релизы
        </div>
        <LastUpdates />
        <div className={clsx(style.title, style.popularGenres)}>
          Популярные жанры
        </div>
        <Genres />
        <div className={clsx(style.title, style.importantNews)}>
          Важные новости
        </div>

        <News />
      </div>
    </PageLayout>
  );
}

export default MainPage;
