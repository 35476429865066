import style from './NewsCard.module.scss';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const formatDate = (dateRaw) => {
  const date = new Date(dateRaw);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  const dateString = `${day}.${month}.${year}`;

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getHours().toString().padStart(2, '0');
  const timeString = `${hours}:${minutes}`;

  return { dateString, timeString };
};

function NewsCard({ imageSrc, name, linkText, linkHref, date }) {
  const { dateString, timeString } = formatDate(date);

  return (
    <div className={clsx(style.ctn)}>
      <img className={style.bg} src={imageSrc} alt="image" />

      <div className={style.info}>
        <div className={style.name}>{name}</div>
        <Link className={style.link} to={linkHref}>
          {linkText}
        </Link>

        <div className={style.date}>
          <span className={style.dateDay}>{dateString}</span>
          <span className={style.dateHour}>{timeString}</span>
        </div>
      </div>
    </div>
  );
}

export default NewsCard;
