import clsx from 'clsx';
import numeral from 'numeral';
import { useContext, useState } from 'react';
import { ShowContext } from '../../common';

import style from './ShowRating.module.scss';
import SpriteIcon from '../SpriteIcon';

function StarIcon({ checked, onChange = Function, value }) {
  return (
    <label className={clsx(style.star)}>
      <input
        type="checkbox"
        checked={checked}
        onChange={() => {
          onChange(value);
        }}
      />
      <SpriteIcon name={'star'} width="33" height="32" />
      <SpriteIcon name={'star-fill'} width="33" height="32" />
    </label>
  );
}

function ShowRating() {
  const show = useContext(ShowContext);

  const [rating, setRating] = useState(null);

  const onRatingChange = (value) => {
    if (value === rating) {
      setRating(null);
    } else {
      setRating(value);
    }
  };
  numeral.locale('ru');

  const ratingString = rating === null ? '?' : rating + 1;

  //const countString = numeral(show.rating.count).format('0a');
  //const countString = numeral(show.rating.count).format('0,0a');

  const format = show.rating.count >= 100000 ? '0,0a' : '0,0';
  const countString = numeral(show.rating.count).format(format);

  return (
    <div className={clsx(style.ctn)}>
      <div className={clsx(style.rating)}>
        <StarIcon checked={rating !== null} />
        <span className={clsx(style.text)}>{show.rating.value}</span>
        <div className={clsx(style.count)}>голосов:{countString}</div>
      </div>

      <div className={clsx(style.select)}>
        <div className={clsx(style.stars)}>
          {new Array(5).fill().map((value, index) => {
            return (
              <StarIcon
                key={index}
                value={index}
                checked={rating === null ? false : index <= rating}
                onChange={onRatingChange}
              />
            );
          })}
        </div>
        <div className={clsx(style.value)}>{ratingString}</div>
      </div>
    </div>
  );
}

export default ShowRating;
