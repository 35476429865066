import style from './News.module.scss';
import clsx from 'clsx';
import { demoNews, getDemoNews } from '../../common';
import NewsCard from './NewsCard';

function News() {
  const news = getDemoNews(3);

  return (
    <div className={clsx(style.grid)}>
      {news.map((props) => {
        return <NewsCard {...props} key={props.id} />;
      })}
    </div>
  );
}

export default News;
