import style from './Footer.module.scss';
import clsx from 'clsx';
import SpriteIcon from './SpriteIcon';

const ContactComponent = ({ children, icon }) => {
  return (
    <div className={clsx(style.contact)}>
      <SpriteIcon width="32" name={icon} />
      <span>{children}</span>
    </div>
  );
};
const SiteComponent = ({ href, icon }) => {
  return (
    <a className={clsx(style.site)} href={href}>
      <SpriteIcon width="48" name={icon} />
    </a>
  );
};

function Footer() {
  return (
    <div className={clsx(style.footer)}>
      <div className={clsx(style.blocks)}>
        <div className={clsx(style.block, style.blockContacts)}>
          <div className={style.contacts}>
            <ContactComponent icon="messagecloud">
              По всем вопросам: <a href="#">Bropines</a>
            </ContactComponent>
            <ContactComponent icon="firo">
              Дизайн: <a href="#">Hideri Lavanette</a>
            </ContactComponent>
            <ContactComponent icon="firo">
              Frontend: <a href="#">Maka (Maksim)</a>
            </ContactComponent>
            <ContactComponent icon="blocks">
              Backend: <a href="#">Makishimu Akuma</a>
            </ContactComponent>
          </div>
        </div>
        <div className={clsx(style.block, style.blockSupport)}>
          <div className={style.supports}>
            <div className={clsx(style.support)}>
              Появилась проблема? – Пиши: <br />
              <a href="mailto:support@dreamerscast.com">
                support@dreamerscast.com
              </a>
            </div>
            <div className={clsx(style.support)}>
              Для правообладателей / Сотрудничество: <br />
              <a href="mailto:partners@dreamerscast.com">
                partners@dreamerscast.com
              </a>
            </div>
            <div className={clsx(style.support)}>
              <a href="#">Политика конфиденциальности</a>
            </div>
          </div>
        </div>
        <div className={clsx(style.block, style.blockSites)}>
          <div className={clsx(style.sites)}>
            <SiteComponent icon="tg" href="#" />
            <SiteComponent icon="vk" href="#" />
            <SiteComponent icon="tiktok" href="#" />
            <SiteComponent icon="youtube" href="#" />
            <SiteComponent icon="onlyfans" href="#" />
            <SiteComponent icon="discord" href="#" />
          </div>
        </div>
      </div>

      <div className={clsx(style.divider)}></div>
      <div className={clsx(style.warnin)}>
        Все материалы на сайте представлены только для ознакомления
      </div>
    </div>
  );
}

export default Footer;
