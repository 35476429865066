import clsx from 'clsx';

import style from './SignIn.module.scss';

import SpriteIcon from '../SpriteIcon';
import { Link, useNavigate } from 'react-router-dom';

import { useContext, useState } from 'react';
import { anyValue, demoUser, UserControlContext } from '../../common';
import { PasswordField } from './InputField';
import { set } from 'lodash';

function FieldError({ errors, name }) {
  return (
    <div
      className={clsx(style.fieldError, { [style.errorShow]: errors[name] })}
    >
      {errors[name]}
    </div>
  );
}

function SignPasswordNewSet({}) {
  const [passwordsShow, setPasswordsShow] = useState(false);

  const [form, setForm] = useState({
    password: '',
    passwordConfirm: '',
  });

  const [errors, setErrors] = useState({
    password: '',
    passwordConfirm: '',
  });

  const setField = (event) => {
    const { name, value } = event.currentTarget;

    setForm({ ...form, [name]: value });

    delete errors[name];
    setErrors(errors);
  };

  const setUser = useContext(UserControlContext);

  let navigate = useNavigate();

  const signUp = () => {
    const newErrors = {};

    if (
      form.password &&
      form.passwordConfirm &&
      form.password !== form.passwordConfirm
    ) {
      set(newErrors, 'password', 'Пароли не совпадают');
      set(newErrors, 'passwordConfirm', 'Пароли не совпадают');
    }

    if (!form.password) {
      set(newErrors, 'password', 'Введи пароль');
    }

    if (!form.passwordConfirm) {
      set(newErrors, 'passwordConfirm', 'Введи пароль');
    }

    if (anyValue(newErrors)) {
      setErrors(newErrors);
      return;
    }

    if (form.password !== 'testerrorpassword') {
      setUser({ ...demoUser }) || navigate('/');
    } else {
      setErrors({
        password: 'Время истекло',
        passwordConfirm: 'Время истекло',
      });
    }
  };

  return (
    <div className={clsx(style.ctn)}>
      <Link to="/sign/in" className={clsx(style.topNavBtn)}>
        <SpriteIcon name="arrow-left-thicc" width="24" />
        <span>Назад</span>
      </Link>

      <div className={clsx(style.cringe, style.titlePasswordNewSet)}>
        Установи новый пароль
        <div className={clsx(style.subtitlePasswordNewSet)}>
          (На этот раз, будь добр, не забудь)
        </div>
      </div>

      <PasswordField
        setShow={setPasswordsShow}
        value={form.password}
        name="password"
        placeholder="Пароль"
        onChange={setField}
        className={clsx(style.passwordNewSetRowPassword)}
        error={errors['password']}
      />

      <PasswordField
        setShow={setPasswordsShow}
        show={passwordsShow}
        value={form.passwordConfirm}
        name="passwordConfirm"
        placeholder="Повтори пароль"
        onChange={setField}
        className={clsx(style.signUpInputRowPasswordConfirm)}
        error={errors['passwordConfirm']}
      />
      {errors.password ? (
        <FieldError name="password" errors={errors} />
      ) : (
        <FieldError name="passwordConfirm" errors={errors} />
      )}

      <button
        disabled={anyValue(errors)}
        type="button"
        className={clsx(style.button, style.buttonPasswordNewSet)}
        onClick={signUp}
      >
        Cохранить
      </button>

      <Link to="/" className={clsx(style.doc)}>
        Политика конфиденциальности
      </Link>
    </div>
  );
}

export default SignPasswordNewSet;
