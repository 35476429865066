import clsx from 'clsx';

import style from './ShowInfo.module.scss';
import SpriteIcon from './../SpriteIcon';
import ShowCover from './ShowCover';
import ShowTags from './ShowTags';
import AddToList from './../AddToList';
import ShowRating from './ShowRating';
import { Link } from 'react-router-dom';
import ShowCast from './ShowCast';
import { useContext } from 'react';
import { ShowContext } from '../../common';
import ShowRelated from './ShowRelated';

function ShowName() {
  return (
    <h1 className={clsx(style.nameCtn)}>
      <span className={clsx(style.name)}>Эта фарфоровая кукла влюбилась</span>
      <br />
      <span className={clsx(style.subname)}>
        Sono Bisque Doll wa Koi wo Suru
      </span>
    </h1>
  );
}

function ShowViews() {
  return (
    <div className={clsx(style.views)}>
      <div className={clsx(style.viewsTop)}>
        <span className={clsx(style.viewsText)}>Просмотрено:</span>

        <button className={clsx(style.viewsButton)} type="button">
          <SpriteIcon width="24" name="minus" />
        </button>

        <span className={clsx(style.viewsStat)}>2/5</span>
        <button className={clsx(style.viewsButton)} type="button">
          <SpriteIcon width="24" name="plus" />
        </button>
      </div>

      <div className={clsx(style.viewsNums)}>
        <SpriteIcon name="eye-fill" width="24" />
        <span>999 999</span>
      </div>
    </div>
  );
}

function ShowCreateRoom() {
  return (
    <Link to="/" className={clsx(style.createRoom)}>
      <SpriteIcon name="tv" width="24" />
      <span>Создать комнату</span>
    </Link>
  );
}

function ShowDownload() {
  return (
    <button type="button" className={clsx(style.download)}>
      <SpriteIcon name="download" width="24" />
    </button>
  );
}

function ShowDescription() {
  const show = useContext(ShowContext);

  return (
    <div className={clsx(style.description)}>
      <div className={clsx(style.descriptionHead)}>Описание:</div>
      <hr className={clsx(style.descriptionHr)} />
      <div className={clsx(style.descriptionText)}>{show.description}</div>
    </div>
  );
}

function ShowInfo({ className }) {
  return (
    <div className={clsx(style.ctn, className)}>
      <div className={clsx(style.top)}>
        <ShowName />
        <ShowViews />
      </div>
      <hr className={clsx(style.hr)} />

      <div className={clsx(style.grid)}>
        <div className={clsx(style.gridCover)}>
          <ShowCover />
        </div>
        <div className={clsx(style.gridTags)}>
          <ShowTags />
        </div>
        <div className={clsx(style.addToList, style.gridAddToList)}>
          <AddToList />
        </div>
        <div className={clsx(style.gridDescription)}>
          <ShowDescription />
        </div>
        <div className={clsx(style.gridRating)}>
          <ShowRating />
        </div>
        <div className={clsx(style.gridCreateRoom)}>
          <ShowCreateRoom />
        </div>
        <div className={clsx(style.gridDownload)}>
          <ShowDownload />
        </div>
        <div className={clsx(style.gridCast)}>
          <ShowCast />
        </div>
      </div>

      <div className={clsx(style.related)}>
        <ShowRelated />
      </div>
    </div>
  );
}

export default ShowInfo;
