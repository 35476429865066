import style from './Player.module.scss';
import clsx from 'clsx';
import EpisodeSelect from './EpisodeSelect';
import SpriteIcon from './../SpriteIcon';
import { useContext } from 'react';
import { ShowContext } from '../../common';

function PlayerButton({ icon, name }) {
  return (
    <button type="button" className={clsx(style.button)}>
      <SpriteIcon width="24" height="24" name={icon} />
    </button>
  );
}

function Player({ className }) {
  const { videoSrc, videoImageSrc } = useContext(ShowContext);

  return (
    <div className={clsx(style.ctn, className)}>
      {/* player here className={clsx(style.player)} */}
    </div>
  );

  return (
    <div className={clsx(style.ctn, className)}>
      <img className={clsx(style.cover)} src={videoImageSrc} alt="cover" />
      <div className={clsx(style.coverFade)}></div>

      <div id="player" className={clsx(style.player)}></div>

      <div className={clsx(style.controlsCtn)}>
        <div className={clsx(style.controls)}>
          <div className={clsx(style.controlsGroup)}>
            <EpisodeSelect />
            <PlayerButton icon="backforward" />
            <PlayerButton icon="forward" />
          </div>
          <div className={clsx(style.controlsGroup)}>
            <PlayerButton icon="chromecast" />
            <PlayerButton icon="download" />
            <PlayerButton icon="share" />
          </div>
        </div>
      </div>

      <button className={clsx(style.playPause)}>
        <SpriteIcon width="96" height="96" name="play" />
      </button>
    </div>
  );
}

export default Player;
