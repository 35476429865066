import clsx from 'clsx';

import style from './SignIn.module.scss';

import SpriteIcon from '../SpriteIcon';
import { Link, Router, useNavigate, useRoutes } from 'react-router-dom';

import sempai from '../../assets/pictures/sempai.png';
import { useContext, useState } from 'react';
import { anyValue, demoUser, UserControlContext } from '../../common';
import { PasswordField, TextField } from './InputField';
import { set } from 'lodash';

function SignIn({}) {
  const [errors, setErrors] = useState({
    name: '',
    password: '',
    any: '',
  });

  const error = Object.values(errors)
    .filter((value) => value)
    .join('\n');

  const [form, setForm] = useState({
    name: '',
    password: '',
    rememberMe: true,
  });

  const setField = (event, valueField = 'value') => {
    const { name } = event.currentTarget;

    const value = event.currentTarget[valueField];

    setForm({ ...form, [name]: value });
    setErrors({});
  };

  const setUser = useContext(UserControlContext);

  let navigate = useNavigate();

  const signInSubmit = () => {
    const newErrors = {};

    if (!form.name) {
      set(newErrors, 'name', 'Введи эл. почту или никнейм');
    }

    if (!form.password) {
      set(newErrors, 'password', 'Введи пароль');
    }

    if (anyValue(newErrors)) {
      setErrors(newErrors);
      return;
    }

    // just for demo
    if (form.password !== '1') {
      setTimeout(() => {
        alert('попробуй пароль 1');
      }, 100);
      setErrors({
        any: 'Неправильный логин или пароль. Гадай теперь, что именно неправильно',
      });

      return;
    }

    console.log({ ...demoUser, name: form.name });
    setUser({ ...demoUser, name: form.name });
    navigate('/sign/in-code');
  };

  return (
    <div className={clsx(style.ctn)}>
      <Link to="/sign/up" className={clsx(style.topNavBtn)}>
        <span>Регистрация</span>
        <SpriteIcon name="user-fill-add" width="24" />
      </Link>

      <div className={clsx(style.cringe, style.titleSignIn)}>
        Войди в меня, семпай!
        <img src={sempai} alt="sempai" width="47" />
      </div>

      <div
        className={clsx(style.error, style.signInTopError, {
          [style.errorShow]: error,
        })}
      >
        {error}
      </div>

      <TextField
        className={clsx(style.signInInputRowName)}
        icon="f-user"
        placeholder="Email или никнейм"
        name="name"
        value={form.name}
        onChange={setField}
      />

      <PasswordField
        className={clsx(style.signInInputRowPassword)}
        value={form.password}
        name="password"
        onChange={setField}
      />

      <div className={clsx(style.controls)}>
        <label className={clsx(style.checkbox)}>
          <input
            checked={form.rememberMe}
            name="rememberMe"
            onChange={(event) => {
              setField(event, 'checked');
            }}
            type="checkbox"
          />
          <div className={clsx(style.checkboxIcon)}>
            <SpriteIcon name="check-v" width="24" />
          </div>
          <span className={clsx(style.checkboxText)}>Запомни меня</span>
        </label>

        <Link to="/sign/password-reset" className={clsx(style.passwordLink)}>
          <span className={clsx(style.passwordLinkUnderlined)}>
            Забыл пароль
          </span>{' '}
          :(
        </Link>
      </div>

      <div className={clsx(style.captha)}>тут будет капча</div>

      <button
        disabled={error}
        type="button"
        className={clsx(style.button)}
        onClick={signInSubmit}
      >
        Войти
      </button>

      <div className={clsx(style.or)}>–или–</div>

      <div className={clsx(style.thirdButtons)}>
        <button
          type="button"
          className={clsx(style.buttonThird, style.buttonThirdGoogle)}
        >
          <SpriteIcon name="c-google" width="24" />
          <span>Войти через Google</span>
        </button>

        <button type="button" className={clsx(style.buttonThird)}>
          <SpriteIcon name="c-vk" width="24" />
          <span>ВКонтакте</span>
        </button>

        <button type="button" className={clsx(style.buttonThird)}>
          <SpriteIcon name="c-tg" width="24" />
          <span>Телеграм</span>
        </button>
      </div>

      <Link to="/" className={clsx(style.doc)}>
        Политика конфиденциальности
      </Link>
    </div>
  );
}

export default SignIn;
