import style from './More.module.scss';
import { useState } from 'react';
import SpriteIcon from '../SpriteIcon';
import { useFloating } from '@floating-ui/react-dom';
import Floating from '../Floating';
import clsx from 'clsx';

const LiComponent = ({ text, href, icon }) => {
  return (
    <a className={style.link} href={href}>
      <SpriteIcon width="24" name={icon} />
      <span>{text}</span>
    </a>
  );
};

function MorePopup() {
  return (
    <div className={style.popup}>
      <LiComponent text="Поддержка" href="#" icon="coin" />
      <LiComponent text="Наша команда" href="#" icon="people" />
      <div className={style.divider} />
      <LiComponent text="FAQ" href="#" icon="faq" />
      <LiComponent text="Правила сайта" href="#" icon="info-bold" />
    </div>
  );
}

function More({ className }) {
  return (
    <Floating
      placement="bottom-center"
      offsetPx={15}
      className={clsx(className, style.ctn)}
      menu={<MorePopup />}
      children={(open, setOpen) => (
        <button className={style.button} onClick={() => setOpen(!open)}>
          <SpriteIcon width="24" name="dots" />
        </button>
      )}
    />
  );
}

export default More;
