import { offset, useFloating } from '@floating-ui/react-dom';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import style from './Floating.module.scss';

function Floating({
  className,
  menu,
  children,
  offsetPx = 21,
  placement = 'bottom-end',
}) {
  const [open, setOpen] = useState(false);

  const parentRef = useRef(null);
  const menuRef = useRef(null);

  const { x, y, reference, floating, strategy } = useFloating({
    placement,
    strategy: 'fixed',
    middleware: [offset(offsetPx)],
  });

  useEffect(() => {
    const onClick = (event) => {
      if (
        parentRef.current.contains(event.target) ||
        menuRef.current?.contains(event.target)
      ) {
        return;
      }

      setOpen(false);
    };
    window.addEventListener('click', onClick);
    return () => window.removeEventListener('click', onClick);
  }, []);

  return (
    <>
      <div
        className={clsx(className)}
        ref={(node) => {
          parentRef.current = node;
          reference(node);
        }}
      >
        {children(open, setOpen)}
      </div>

      {open &&
        createPortal(
          <div
            className={clsx(style.floatingMenu)}
            ref={(node) => {
              menuRef.current = node;
              floating(node);
            }}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            {menu}
          </div>,
          document.querySelector('#root'),
        )}
    </>
  );
}

export default Floating;
